const isLocal = true
const LOCAL = 'http://localhost:8080'
const FIREBASE_URL = "https://us-central1-woodies-wash-shack-82050.cloudfunctions.net"
const MAIL = "/mail"

export let BASE = FIREBASE_URL;

if (isLocal) {
    BASE = FIREBASE_URL
}
