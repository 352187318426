import React, { Component } from 'react'
import { Dots } from 'react-activity';
import { withRouter } from 'react-router';

import { REWARDS_SLUG } from '.'
import { updateReward } from '../api';
import { dashString, onVerifyString } from '../helpers'
import { BLUE, NAVY, RED } from '../theme'
import { Button, Input } from '../ui-elements'

export class EditRewardPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            reward: {
                pointValue: '',
                name: ''
            },
            error: {
                flag: false,
                message: ''
            },
            isPointValueValid: false,
            isLoading: false
        }
    }

    componentDidMount() {
        this.setRewardData()
    }

    setRewardData = () => {
        const { reward } = this.props.location.state

        this.setState({
            reward: {
                name: reward.name,
                pointValue: reward.pointValue
            },
            isPointValueValid: true
        })

    }

    onChangeText = (text, type) => {
        switch (type) {
            case ('pointValue'):

                const isPointValueValid = onVerifyString(text)

                this.setState(prevState => ({
                    reward: {
                        ...prevState.reward,
                        pointValue: text,
                    },
                    isPointValueValid
                }))
                break
        }
    }

    onVerifyReward = () => {
        const { isPointValueValid } = this.state;

        if (!isPointValueValid) return false

        return true
    }

    onCancel = () => {
        const { reward } = this.props.location.state;

        this.props.history.push({
            pathname: REWARDS_SLUG + '/' + dashString(reward.name),
            state: {
                reward: {
                    ...reward
                }
            }
        })
    }

    onUpdateReward = async () => {
        try {
            const { id } = this.props.location.state.reward;
            this.setState({ isLoading: true })

            const flag = this.onVerifyReward()

            if (!flag) {
                const error = {
                    flag: true,
                    message: "Please fill out required felids."
                }

                this.setState({ error: error, isLoading: false })

                return
            }

            await updateReward(id, this.state.reward)

            this.props.history.push({
                pathname: REWARDS_SLUG,
            })

            this.setState({ isLoading: false })
        } catch (error) {

            const e = {
                flag: true,
                message: "Error adding location."
            }

            this.setState({ error: e, isLoading: false })

        }
    }

    render() {
        const { isLoading, reward, isPointValueValid, error } = this.state;
        const { } = this.props;
        const { name, pointValue } = reward

        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                <div className="location-box-container mr-2">
                    <div className="F5 navy" style={{ marginBottom: '15px' }}>{name}</div>

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter Point Value'}
                        headline={'POINT VALUE'}
                        onUpdateText={(text) => this.onChangeText(text, "pointValue")}
                        showErrStyle={!isPointValueValid}
                        editable={true}
                        value={pointValue}
                    />

                    {error.flag && (
                        <div className="F3 red" style={{ marginLeft: '16px', marginTop: '16px', marginBottom: '16px', textAlign: 'center' }}>** Error: {error.message}</div>
                    )}

                    <div className="mb-2 distribute distribute-row" >

                        {(isLoading === false)
                            ? <>
                                <Button
                                    width={'95%'}
                                    onClick={this.onCancel}
                                    textColor={'white'}
                                    backgroundColor={BLUE}
                                    text={"Cancel"} />

                                <div className="ml-1" />

                                <Button
                                    width={'95%'}
                                    onClick={this.onUpdateReward}
                                    textColor={'white'}
                                    backgroundColor={RED}
                                    text={"Submit"} />
                            </>
                            : <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '100%' }}>
                                <Dots color={NAVY} />
                            </div>

                        }
                    </div>

                </div>

                <div className="location-box-container">

                </div>
            </div>
        )
    }
}

export default withRouter(EditRewardPage);