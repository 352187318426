import React, { Component } from 'react'
import {
    withRouter
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { addLocation } from '../api'
import { BLUE, NAVY, RED } from '../theme'
import { Button, Input, DropZone, } from '../ui-elements'
import { DayDetail, DayPicker } from '../components/day-picker'
import { LOCATIONS_SLUG } from './'
import { onVerifyLat, onVerifyLng, onVerifyPhone, onVerifyString } from '../helpers';
import { Dots } from 'react-activity';

class EditLocationPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            location: {
                name: '',
                address: '',
                lat: '',
                lng: '',
                about: '',
                phoneNumber: '',
                reviewLink: '',
                backgroundImgUrl: "",
                locationCode: "",
                daysOfWeek: [],
                id: ""
            },
            isLocationCodeValid: false,
            isBackgroundImgUrlValid: false,
            isReviewLinkValid: false,
            isAddressValid: false,
            isLngValid: false,
            isAboutValid: false,
            isPhoneValid: false,
            isLatValid: false,
            isNameValid: false,
            isImageUpdated: false,
            error: {
                flag: false,
                message: ''
            },
            isLoading: false
        }
    }

    componentDidMount() {
        this.setLocationData()
    }

    setLocationData = () => {
        if (this.props.location.state === undefined) return

        const { location } = this.props.location.state;

        this.setState({
            location: {
                name: location.name,
                address: location.address,
                lat: location.geolocation._lat,
                lng: location.geolocation._long,
                about: location.description,
                phoneNumber: location.phone,
                locationCode: location.locationCode,
                reviewLink: location.publicReviewUrl,
                id: location.id,
                backgroundImgUrl: location.backgroundImgUrl,
                daysOfWeek: [...location.hoursOfOperation],
            },
            isLocationCodeValid: true,
            isNameValid: true,
            isAddressValid: true,
            isBackgroundImgUrlValid: true,
            isImageUploading: false,
            isReviewLinkValid: true,
            isPhoneValid: true,
            isAboutValid: true,
            isLngValid: true,
            isLatValid: true,
        })
    }

    


    onAddDayTime = (day) => {
        const { daysOfWeek } = this.state.location;

        const newArray = [
            ...daysOfWeek,
            day
        ]

        newArray.sort((a, b) => {
            return a.order - b.order
        })

        this.setState(prevState => ({
            location: {
                ...prevState.location,
                daysOfWeek: newArray
            }
        }))

    }

    onAddLocation = async () => {
        try {

            this.setState({ isLoading: true })
            const flag = this.onVerifyLocation()

            if (!flag) {
                const error = {
                    flag: true,
                    message: "Please fill out required felids."
                }

                this.setState({ error: error, isLoading: false })

                return
            }

            await addLocation({...this.state.location, isImageUpdated: this.state.isImageUpdated})

            this.onGoLocationPage()

        } catch (error) {
            this.setState({ isLoading: false })

            alert("Error Adding Location", error.code)
            console.log("Location Error", error.code)

        }
    }

    onRemoveDayTime = (index) => {
        let { daysOfWeek } = this.state.location

        if (index > -1) {
            daysOfWeek.splice(index, 1);
        }

        this.setState({ daysOfWeek })
    }

    onGoLocationPage = () => {
        const { history } = this.props;

        history.push(LOCATIONS_SLUG);
    }

    onChangeText = (text, type) => {
        switch (type) {
            case ('name'):

                const isNameValid = onVerifyString(text)

                this.setState(prevState => ({
                    isNameValid,
                    location: {
                        ...prevState.location,
                        name: text
                    }
                }))
                break

            case ('address'):
                const isAddressValid = onVerifyString(text)

                this.setState((prevState) => ({
                    isAddressValid,
                    location: {
                        ...prevState.location,
                        address: text
                    }
                }))
                break

            case ('lat'):
                const isLatValid = onVerifyLat(text)

                this.setState(prevState => ({
                    isLatValid,
                    location: {
                        ...prevState.location,
                        lat: text
                    }
                }))
                break
            case ('lng'):
                const isLngValid = onVerifyLng(text)

                this.setState(prevState => ({
                    isLngValid,
                    location: {
                        ...prevState.location,
                        lng: text
                    }
                }))
                break

            case ('about'):

                const isAboutValid = onVerifyString(text)

                this.setState(prevState => ({
                    isAboutValid,
                    location: {
                        ...prevState.location,
                        about: text
                    }
                }))
                break

            case ('phoneNumber'):
                const isPhoneValid = onVerifyPhone(text)

                this.setState(prevState => ({
                    isPhoneValid,
                    location: {
                        ...prevState.location,
                        phoneNumber: text
                    }
                }))
                break

            case ('reviewLink'):
                const isReviewLinkValid = onVerifyString(text)

                this.setState(prevState => ({
                    isReviewLinkValid,
                    location: {
                        ...prevState.location,
                        reviewLink: text
                    }
                }))
                break

            case ('locationCode'):
                const isLocationCodeValid = onVerifyString(text)

                this.setState(prevState => ({
                    isLocationCodeValid,
                    location: {
                        ...prevState.location,
                        locationCode: text
                    }
                }))
                break
        }
    }

    onVerifyLocation = () => {
        const {
            isLatValid,
            isLngValid,
            isAboutValid,
            isNameValid,
            isPhoneValid,
            isAddressValid,
            isReviewLinkValid,
            isBackgroundImgUrlValid,
        } = this.state;

        if (
            !isAboutValid
            || !isLatValid
            || !isLngValid
            || !isNameValid
            || !isPhoneValid
            || !isAddressValid
            || !isReviewLinkValid
            || !isBackgroundImgUrlValid
        ) return false

        return true
    }

    handleImage = async (imageRef) => {
        try {
            this.setState((prevState) => ({
                isBackgroundImgUrlValid: true,
                isImageUploading: false,
                isImageUpdated: true ,
                location: {
                    ...prevState.location,
                    backgroundImgUrl: imageRef,
                }

            }))

        } catch (e) {
            this.setState({ isImageUploading: false})
            console.log(e)
        }
    }

    render() {

        const {
            isLatValid,
            isLngValid,
            isAboutValid,
            isNameValid,
            isPhoneValid,
            isAddressValid,
            isReviewLinkValid,
            isBackgroundImgUrlValid,
            isLocationCodeValid,
            error,
            isImageUploading,
            isLoading,
            location
        } = this.state
        const { } = this.props
        const {
            name,
            address,
            phoneNumber,
            lat,
            lng,
            about,
            reviewLink,
            backgroundImgUrl,
            daysOfWeek,
            locationCode
        } = location


        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                <div className="location-page-container ml-2 mr-2" >
                    <div className="location-box-container">

                        <div className="F5 navy" style={{ marginBottom: '15px' }}>{(this.props.location.state === undefined) ? "Add Location" : "Edit " + name} </div>
                        <div className="F3 navy" style={{ marginBottom: '25px', }}>{(this.props.location.state === undefined) ? "Fill out form in its entirety to add a new woodies location." : about} </div>


                        <div className="F4 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto' }}>
                            LOCATION DETAILS
                        </div>

                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Name'}
                            headline={'NAME'}
                            showErrStyle={!isNameValid}
                            value={name}
                            onUpdateText={(text) => this.onChangeText(text, 'name')} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Address'}
                            headline={'ADDRESS'}
                            value={address}
                            showErrStyle={!isAddressValid}
                            onUpdateText={(text) => this.onChangeText(text, 'address')} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Location'}
                            value={lat}
                            headline={'LATITUDE'}
                            showErrStyle={!isLatValid}
                            onUpdateText={(text) => this.onChangeText(text, 'lat')} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Location'}
                            headline={'LONGITUDE'}
                            showErrStyle={!isLngValid}
                            value={lng}
                            onUpdateText={(text) => this.onChangeText(text, 'lng')} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'About Location'}
                            headline={'ABOUT'}
                            showErrStyle={!isAboutValid}
                            value={about}
                            onUpdateText={(text) => this.onChangeText(text, 'about')} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Phone Number'}
                            headline={'PHONE NUMBER'}
                            value={phoneNumber}
                            showErrStyle={!isPhoneValid}
                            onUpdateText={(text) => this.onChangeText(text, 'phoneNumber')} />

                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Location Code'}
                            headline={'LOCATION CODE'}
                            value={locationCode}
                            showErrStyle={!isLocationCodeValid}
                            onUpdateText={(text) => this.onChangeText(text, 'locationCode')} />


                        <div className="F4 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto' }}>
                            LOCATION HOURS
                        </div>

                        {daysOfWeek.map((day, index) => {
                            return (
                                <DayDetail {...day} removeDayTime={() => this.onRemoveDayTime(index)} />
                            )
                        })}

                        <DayPicker
                            configuredDays={daysOfWeek}
                            addDay={this.onAddDayTime} />

                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Web Link'}
                            headline={'GOOGLE REVIEW LINK'}
                            showErrStyle={!isReviewLinkValid}
                            value={reviewLink}
                            onUpdateText={(text) => this.onChangeText(text, 'reviewLink')} />

                        {error.flag && (
                            <div className="F3 red" style={{ marginLeft: '16px', marginTop: '16px', marginBottom: '16px', textAlign: 'center' }}>** Error: {error.message}</div>
                        )}

                        <div className="mb-2 distribute distribute-horizontal distribute-center" >
                            {(isLoading === false)
                                ? <>
                                    <Button
                                        width={'100%'}
                                        onClick={this.onGoLocationPage}
                                        textColor={'white'}
                                        backgroundColor={RED}
                                        text={"CANCEL"} />

                                    <div style={{ width: '5%' }} />

                                    <Button
                                        width={'100%'}
                                        onClick={this.onAddLocation}
                                        textColor={'white'}
                                        backgroundColor={BLUE}
                                        text={"SUBMIT"} />
                                </>
                                : <Dots color={NAVY} />
                            }


                        </div>

                    </div>
                </div>

                <div className="location-box-container" >

                    <DropZone
                        dropHeight={'25vh'}
                        imageHeight={'150px'}
                        imageWidth={'450px'}
                        imageConfigText={'Please upload an image with a ratio of 1 to 3 (height/width Ex. 100px/300px).'}
                        showErrStyle={!isBackgroundImgUrlValid}
                        headlineText={'ADD PHOTO/MEDIA'}
                        loading={isImageUploading}
                        acceptedFiles={'image/png'}
                        imageUrl={backgroundImgUrl}
                        returnImage={(file) => this.handleImage(file)}
                    />

                </div>
            </div>

        )
    }
}

export default withRouter(EditLocationPage);

EditLocationPage.prototypes = {
    location: PropTypes.object,
}

EditLocationPage.defaultProps = {
    location: null,
};
