import React, { Component } from 'react'
import { withRouter } from 'react-router';

import { Button, Input, GhostButton, MembershipDetail } from '../ui-elements'
import { RED } from '../theme'
import { MANAGE_MEMBERS_SLUG } from '.';
import { dashString } from '../helpers';

class MemberDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            rewardBalance: '',
            isRewardBalanceValid: false,
            sonnysClientId: '',
            isSonnysClientIdValid: false,
            nameFirst: '',
            isNameFirstValid: false,
            nameLast: "",
            isNameLastValid: false,
            phone: "",
            isPhoneValid: false,
            email: "",
            isEmailValid: false,
            vehicles: null
        }
    }


    componentDidMount() {
        this.setMemberData()
    }

    setMemberData = () => {
        if (this.props.location.state === undefined) return

        const { member } = this.props.location.state;

        this.setState({
            rewardBalance: member.rewardBalance,
            isRewardBalanceValid: true,
            sonnysClientId: (member.sonnysClientId === null) ? "Connect Sonny Id" : member.sonnysClientId,
            isSonnysClientIdValid: (member.sonnysClientId === null) ? false : true,
            nameFirst: member.nameFirst,
            isNameFirstValid: true,
            nameLast: member.nameLast,
            isNameLastValid: true,
            phone: member.phone,
            isPhoneValid: true,
            email: member.email,
            isEmailValid: true,
            photoUrl: member.photoUrl,
            isPhotoUrlValid: false,
            vehicles: member.vehicles
        })
    }

    onNavEditMember = () => {
        const { member } = this.props.location.state;
        const isNameComplete = (member.nameFirst || member.nameLast) ? true : false;
        const nameFull = (isNameComplete) ? member.nameFirst + " " + member.nameLast : "Member";

        this.props.history.push({
            pathname: MANAGE_MEMBERS_SLUG + '/' + dashString(isNameComplete ? nameFull : "member") + "/edit",
            state: {
                member: {
                    ...member
                }
            }
        })
    }

    render() {
        const { member } = this.props.location.state;
        const { rewardBalance,
            isRewardBalanceValid,
            isSonnysClientIdValid,
            isNameFirstValid,
            isNameLastValid,
            isPhoneValid,
            isEmailValid,
            phone,
            email,
            sonnysClientId,
            nameFirst,
            nameLast,
            photoUrl,
            vehicles
        } = this.state;
        const isNameComplete = (member.nameFirst || member.nameLast) ? true : false;
        const nameFull = (isNameComplete) ? member.nameFirst + " " + member.nameLast : "Member";

        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>

                <div className="location-box-container mr-2">
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                        <div className="F5 navy" style={{ marginBottom: '15px' }}>{nameFull}</div>
                    </div>

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter First Name'}
                        headline={'First Name'}
                        showErrStyle={!isNameFirstValid}
                        editable={false}
                        value={nameFirst}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter sunny client id'}
                        headline={'Last Name'}
                        showErrStyle={!isNameLastValid}
                        editable={false}
                        value={nameLast}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter a phone number'}
                        headline={'EMAIL'}
                        showErrStyle={!isEmailValid}
                        editable={false}
                        value={email}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter a phone number'}
                        headline={'PHONE NUMBER'}
                        showErrStyle={!isPhoneValid}
                        editable={false}
                        value={phone}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter Reward Points'}
                        headline={'REWARD POINTS'}
                        showErrStyle={!isRewardBalanceValid}
                        editable={false}
                        value={rewardBalance}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter sunny client id'}
                        headline={'SONNY CLIENT ID'}
                        showErrStyle={!isSonnysClientIdValid}
                        editable={false}
                        value={sonnysClientId}
                    />

                    <div className="mb-2 distribute distribute-row" >

                        <Button
                            width={'95%'}
                            onClick={this.onNavEditMember}
                            textColor={'white'}
                            backgroundColor={RED}
                            text={"Edit Member"} />
                    </div>
                </div>

                <div className="location-box-container">
                    <div className="F5 navy" style={{ marginBottom: '15px' }}>Memberships</div>

                    {(member.vehicles.length === 0)
                        ?
                        <GhostButton
                            onClick={() => null}>
                            <p>No Membership</p>
                        </GhostButton>
                        :
                        <div>
                            {member.vehicles.map((v, i) => <MembershipDetail {...v} />)}
                        </div>
                    }

                </div>

            </div>
        )
    }
}

export default withRouter(MemberDetailPage);
