import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getLocations } from '../api'

import { AddLocationDetail, ListDetail } from '../components'
import { onSearchFelid } from '../helpers'
import { LIGHT_BLUE, UNIT } from '../theme'
import { SearchBar } from '../ui-elements'


class LocationsPage extends Component {
    constructor() {
        super()

        this.state = {
            locationData: [],
            toSearchData: [],
            listLoading: false,
            toSearchValue: ''
        }
    }


    componentDidMount() {
        this.init()
    }

    init = async () => {
        try {
            this.setState({ listLoading: true })
            const res = await getLocations()

            this.setState({ locationData: res, listLoading: false })

        } catch (error) {
            console.log("Locations Error", error)
            alert("Error Loading Data")
            this.setState({ listLoading: false })
        }
    }

    onSearch = (toSearch) => {
        const res = onSearchFelid(toSearch, this.state.locationData);

        this.setState({ toSearchValue: toSearch, toSearchData: res })
    }

    render() {

        const { locationData, listLoading, toSearchData, toSearchValue } = this.state
        const { } = this.props

        return (
            <div className="location-page-container">
                <SearchBar width={'30%'} onSearch={(text) => this.onSearch(text)} />

                <ListDetail
                    loading={listLoading}
                    components={new AddLocationDetail()}
                    data={(toSearchValue != '') ? toSearchData : locationData} />

            </div>
        )
    }
}

export default withRouter(LocationsPage);
