import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getFirestore, getDoc, doc } from 'firebase/firestore';

export function onLogin(email, password) {
    return new Promise(async (resolve, reject) => {
        try {
            const auth = getAuth();
            const res = await signInWithEmailAndPassword(auth, email, password);

            resolve(res)

        } catch (e) {

            reject(e)
        }
    })
}

export function onLogOut(email, password) {
    const auth = getAuth();
    return auth.signOut();
}

export function onForgotPassword(email) {
    return new Promise(async (resolve, reject) => {
        try {
            const auth = getAuth();

            const res = await sendPasswordResetEmail(auth, email);

            resolve(res)

        } catch (e) {
            reject(e)
        }
    })
}

export function isUserAdmin(id) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await getFirestore();
            const docRef = doc(db, "admins", id);
            let snapshot = await getDoc(docRef);
            const data = snapshot.data()

            if (data === undefined) {
                resolve(false)
            }

            resolve(true)
        } catch (error) {
            resolve(false)
        }
    })
}

