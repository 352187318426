import React, { useState } from "react";

import Icon from '../assets/icons/search.png'
import { BLUE, NAVY } from "../theme";

export const SearchBar = ({ width, onSearch }) => {
    const [text, updateText] = useState("");


    function onChangeText(evt) {
        onSearch(evt.target.value)
        updateText(evt.target.value)
    }

    return (
        <div style={{ width: width, flexDirection: 'column' }} className="mb-2">

            <div style={{ flexDirection: 'row' }} className="search-bar-container">

                <img src={Icon} className="mr-1 search-icon" />

                <input
                    placeholder={"Search for item"}
                    class="input"
                    style={{ background: 'transparent' }}
                    type={'text'}
                    value={text}
                    onChange={(event) => onChangeText(event)}
                />

            </div>

            <div style={{ width: '100%', height: '.15vh', backgroundColor: NAVY }} />
        </div>

    )
}
