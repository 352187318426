import React, { useState } from 'react'
import { BLUE, LIGHT_BLUE, UNIT, RED, weekDayOptions, hourOptions } from '../../theme'
import { DayTime } from '../../api/firebase-classes'
import { Button, SelectInput } from '../../ui-elements'

export const DayPicker = ({ addDay, configuredDays }) => {
    const cardRef = React.useRef()
    const [name, setName] = useState("")
    const [close, setClose] = useState("")
    const [open, setOpen] = useState("")
    const [error, toggleError] = useState({
        enabled: false,
        message: ''
    })

    function _addDay() {
        if (name === "") {
            triggerErrorAnimation()
            toggleError({
                enabled: true,
                message: 'Please select a week day'
            })

            return
        }

        let obj = configuredDays.find(obj => obj.name === name);

        if (obj != undefined) {
            triggerErrorAnimation()
            toggleError({
                enabled: true,
                message: 'Day has already been configured'
            })

            return
        }

        if (open === "") {
            triggerErrorAnimation()
            toggleError({
                enabled: true,
                message: 'Please select a open time'
            })
            return
        }

        if (close === "") {
            triggerErrorAnimation()
            toggleError({
                enabled: true,
                message: 'Please select a closed time'
            })
            return
        }

        const isTimeValid = validateTime()

        if (!isTimeValid) {
            triggerErrorAnimation()
            toggleError({
                enabled: true,
                message: 'Enter a time thats after your open hours'
            })

            return
        }

        addDay(new DayTime(name, close, open))
    }

    function _setClose(time) {
        setClose(time)

    }

    function _setOpen(time) {
        setOpen(time)
    }

    function triggerErrorAnimation() {
        const card = cardRef.current;

        card.classList.add(`animation-trigger`);

        setTimeout(() => {
            card.classList.remove(`animation-trigger`);
        }, 900)
    }


    function validateTime() {
        if (close === "" || open === "") return

        const openSplitTime = open.split(":")
        const openHour = Number(openSplitTime[0])
        const openMin = Number(openSplitTime[1])


        const closeSplitTime = close.split(":")
        const closeHour = Number(closeSplitTime[0])
        const closeMin = Number(closeSplitTime[1])

        if (openHour >= closeHour) {

            if (openHour > closeHour) return false

            if (openHour === closeHour && openMin >= closeMin) return false

            return true
        }

        if (openHour === closeHour && openMin === closeMin) return false

        return true
    }

    return (
        <>
            <div
                ref={cardRef}
                className="day-picker-container"
                style={styles.dayPickerContainer}>

                <div style={{ width: '47.5%' }}>

                    <SelectInput
                        onChangeSelection={(evt) => setName(evt.value)}
                        headline={'DAYS OF OPERATION'}
                        selectOptions={weekDayOptions} />

                </div>

                <div
                    className="F3 navy "
                    style={styles.pickerContainer}>

                    <div style={{ flexDirection: 'column', width: '100%' }}>
                        <SelectInput
                            headline={'OPEN HOURS'}
                            onChangeSelection={(evt) => _setOpen(evt.label)}
                            selectOptions={hourOptions} />
                    </div>

                    <div style={{ width: '10%' }} />

                    <div style={{ flexDirection: 'column', width: '100%' }}>
                        <SelectInput
                            headline={'CLOSED HOURS'}
                            onChangeSelection={(evt) => _setClose(evt.label)}
                            selectOptions={hourOptions} />

                    </div>
                </div>

            </div>

            {error.enabled && (
                <div className="F3 red" style={{ marginLeft: '16px', width: '60%', marginTop: '16px' }}>** Error: {error.message}</div>
            )}

            <div style={{ marginTop: '15px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <Button
                    width={'25%'}
                    onClick={_addDay}
                    textColor={'white'}
                    backgroundColor={BLUE}
                    text={"ADD A DAY "} />

                <div className="F3 navy" style={{ marginLeft: '16px', width: '60%', }}>** Any days not submitted it is assumed those days the car wash is closed</div>
            </div>
        </>
    )
}

const styles = {
    dayPickerContainer: {
        backgroundColor: LIGHT_BLUE,
        borderRadius: UNIT
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    },
    xIconContainer: {
        display: 'flex',
        height: '30px',
        width: '30px',
        backgroundColor: RED,
        borderRadius: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
}
