import React, { useState } from 'react'

function Set({ component }) {
    this.renderComponent = component
}

export const ScrollableListView = (props) => {
    const { onClick, data, width } = props;

    return (
        <div
            class=""
            onClick={onClick}>


        </div>
    )
}
