import React, { useState } from 'react'

export const GhostButton = (props) => {
    const [active, toggleActive] = useState(null);
    const { children, onClick } = props;

    function onToggleButton() {
        toggleActive(!active)
    }
    return (
        <div
            style={{ borderColor: 'black' }}
            class={"F1 ghost-button" }
            onMouseDown={onToggleButton}
            onMouseUp={onToggleButton}
            onClick={onClick}>
            {children}
        </div >
    )
}
