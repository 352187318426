import React, { useState } from 'react'

import HeaderImage from '../assets/images/header.png';

export const NotFoundPage = (props) => {
    const { } = useState(null);
    const { } = props;

    return (
        <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <img src={HeaderImage} style={{ width: '2750px', height: '100px', zIndex: 1000 }} />

            <div className="distribute distribute-center ">
                <div className="login distribute distribute-center" style={{ marginTop: '-100px', }}>
                    <div className="login-container distribute distribute-center">
                        <div class="F5 navy" style={{ alignSelf: 'center', padding: '30px' }}>Error 404</div>
                        <div class="F2 navy" style={{ alignSelf: 'center', marginBottom: '30px' }}>Page Not Found</div>
                    </div>
                </div>
            </div>
        </div>
    )
}