import React, { Component } from 'react'

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { isUserAdmin } from '../api';
import { Loading } from '../ui-elements';

export const UserContext = React.createContext()

export class UserProvider extends Component {
    constructor() {
        super()

        this.subscriber = null

        this.state = {
            isAppReady: false,
            isAdmin: false,
            isAuthenticated: null,
            user: null
        }
    }

    async componentDidMount() {
        await this.init()
    }

    init = async () => {
        try {
            const auth = getAuth();

            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    const isAdmin = await isUserAdmin(user.uid); 

                    this.setState({ user: user, isAuthenticated: true, isAppReady: true, isAdmin: isAdmin })
                } else {
                    this.setState({ user: null, isAuthenticated: false, isAppReady: true, isAdmin: false })
                }
            });
        } catch (e) {
            console.log("ERROR", e)
        }
    }

    render() {
        const { user, isAuthenticated, isAppReady, isAdmin } = this.state
        const { children } = this.props

        const value = {
            user: user,
            isAdmin: isAdmin,
            isAuthenticated: isAuthenticated,
            onLogOut: () => this.onLogOut()
        }

        return (
            <UserContext.Provider value={value}>
                {(isAppReady === false)
                    ? <Loading hasBackground={true}/>
                    : children
                }
            </UserContext.Provider>
        )
    }
}

export const Consumer = UserContext.Consumer;

export function withUserContext(Component) {
    return function UserManager(props) {
        return (
            <UserContext.Consumer >
                {context => <Component {...props} userContext={context} />}
            </UserContext.Consumer>
        )
    }
}