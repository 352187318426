import { getFirestore, collection, getDocs, setDoc, GeoPoint, doc, deleteDoc, getDoc } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Axios from 'axios'

import { BASE } from './'
import { asyncForEach } from "../helpers";
import { getAuth } from "firebase/auth";

export async function getIdToken() {
    const auth = getAuth();
    return await auth.currentUser?.getIdToken();
}

export async function getUsers() {
    return new Promise(async (resolve, reject) => {
        try {
            let users = [];

            const db = getFirestore();
            const snapshot = await getDocs(collection(db, "users"));

            snapshot.forEach(async (doc, index) => users.push({ ...doc.data(), id: doc.id }))
            await asyncForEach(users, async (user, index) => users[index].vehicles = await getVehicles(user.id))

            resolve(users)
        } catch (error) {
            console.log("GET USERS", error.code)
            reject(error)
        }
    })
}

export function getUserById(userId) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = getFirestore();
            const userRef = collection(db, "users")
            const userSnapshot = await getDoc(doc(userRef, userId));
            const vehicles = await getVehicles(userId)

            const user = {
                ...userSnapshot.data(),
                vehicles
            }

            resolve(user)
        } catch (error) {
            reject(error)
        }
    })
}

export function updateUser(userId, payload) {
    const db = getFirestore();
    const userRef = doc(db, 'users', userId)

    return setDoc(userRef, payload)
}


export async function getVehicles(userId) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = getFirestore();
            const vehicleSnapShot = await getDocs(collection(db, "users", userId, "vehicles"))
            const vehicles = vehicleSnapShot.docs.map(doc => {
                return { ...doc.data(), id: doc.id }
            })

            resolve(vehicles)
        } catch (error) {
            reject(error)
        }
    })
}

export async function connectExistingMembership(userId, payload) {
    const idToken = await getIdToken();
    // Assign a customer ID and token from Sonny’s to a user’s app account. Once these are received from Sonny’s, they should be written to the appropriate fields in the user document in Firestore.      
    const url = `${BASE}/v1/users/${userId}/connect`;
    const config = {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    };
    return Axios.post(url, payload, config);
}

export async function syncMemberships(userId) {
    const idToken = await getIdToken();
    const url = `${BASE}/v1/users/${userId}/vehicles/sync`;
    const config = {
        headers: {
            'Authorization': `Bearer ${idToken}`
        }
    };
    return Axios.post(url, undefined, config);
}
