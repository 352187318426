
import React, { useState } from 'react'

import EyeOpen from '../assets/icons/eye.png'
import EyeClosedOpen from '../assets/icons/eye-closed.png'
import { UNIT_VERTICAL } from '../theme';

export const LoginForm = (props) => {
    const { placeholder, type, iconSource, onUpdateText } = props;
    const [inputType, updateInputType] = useState('password')
    const [text, updateText] = useState("");

    function onChangeText(evt) {
        onUpdateText(evt.target.value)
        updateText(evt.target.value)
    }

    function onClearText() {
        updateText("")
    }

    function onToggleInputType() {
        updateInputType((inputType === 'password') ? 'text' : 'password')
    }

    return (
        <div class="input-container" style={{ width: '100%' }}>
            <img
                src={iconSource}
                style={styles.icon2}
                class="mr-1"
            />

            <input
                placeholder={placeholder}
                class="input"
                type={(type != 'password') ? 'text' : inputType}
                value={text}
                onChange={(event) => onChangeText(event)}
            />

            {(type === 'password')
                ? <img
                    src={inputType != 'password' ? EyeOpen : EyeClosedOpen}
                    style={styles.icon2}
                    class="ml-1"
                    onClick={onToggleInputType} />
                : null
            }
        </div>
    )
}

const styles = {
    icon: {
        height: '1em',
        width: '1em',
    },

    icon2: {
        height: '1.5em',
        width: '1.5em',
    },
}
