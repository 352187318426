import PropTypes from 'prop-types';

import { LIGHT_BLUE, UNIT, RED } from '../../theme'
import XIcon from '../../assets/icons/x_white.png'

export const DayDetail = ({ name, close, open, removeDayTime, editable }) => {
    return (
        <div
            className="pt-1 pb-1 pr-1 pl-1 mb-1 "
            style={styles.dayPickerContainer}>

            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className="F3 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto', textTransform: 'uppercase' }}>
                    {name}
                </div>

                <div>
                    <div style={{ marginBottom: '10px', marginTop: '10px', width: 'auto', display: 'flex', flexDirection: 'row' }}>
                        <div className="F3 navy" style={{ textTransform: 'uppercase' }}>Open</div>
                        <div style={{ width: '5px' }} />
                        <div className="F3 navy" style={{ textTransform: 'uppercase' }}>{open}</div>
                    </div>

                    <div style={{ marginBottom: '10px', marginTop: '10px', width: 'auto', display: 'flex', flexDirection: 'row' }}>
                        <div className="F3 navy" style={{ textTransform: 'uppercase' }}>CLOSE</div>
                        <div style={{ width: '5px' }} />
                        <div className="F3 navy" style={{ textTransform: 'uppercase' }}>{close}</div>
                    </div>
                </div>

                {(editable) && (
                    <div style={styles.xIconContainer} onClick={removeDayTime}>
                        <img src={XIcon} style={{ height: '15px', width: '15px' }} />
                    </div>
                )}


            </div>
        </div>
    )
}

const styles = {
    dayPickerContainer: {
        backgroundColor: LIGHT_BLUE,
        borderRadius: UNIT
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    },
    xIconContainer: {
        display: 'flex',
        height: '30px',
        width: '30px',
        backgroundColor: RED,
        borderRadius: '5px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
}

DayDetail.prototypes = {
    name: PropTypes.string,
    close: PropTypes.string,
    open: PropTypes.string,
    removeDayTime: PropTypes.func,
    editable: PropTypes.bool

}

DayDetail.defaultProps = {
    name: "",
    close: "",
    open: "",
    removeDayTime: () => null,
    editable: true
};