import React, { Component } from 'react'
import {
    Route,
    Redirect,
    Switch,
} from 'react-router-dom';
import GlobalEvent from 'js-events-listener'

import { NotFoundPage, LoginPage, ForgotPasswordPage, NotAdministratorAccessPage,  } from '.'
import { ProtectedRoute } from '../components';
import EditLocationPage from './EditLocationPage';
import LocationDetailPage from './LocationDetailPage'
import LocationsPage from './LocationsPage'
import ManageMembersPage from './ManageMembersPage'
import RewardsPage from './RewardsPage'
import EditMemberDetailPage from './EditMemberDetailPage';
import MemberDetailPage from './MemberDetailPage';
import RewardDetailPage from './RewardDetailPage';
import EditRewardPage from './EditRewardPage';
import { LogoutAlert, DeleteLocationAlert, ScreenNotSupported } from '../ui-elements'

// Root Routes
export const LOCATIONS_SLUG = '/locations';
export const UPLOAD_MEMBERS_SLUG = '/upload-members';
export const MANAGE_MEMBERS_SLUG = '/manage-members';
export const WASH_CODES_SLUG = '/wash-codes';
export const REWARDS_SLUG = '/rewards';
export const LOGIN_SLUG = '/login';
export const FORGOT_PASSWORD_SLUG = '/forgot-password';
export const NO_ADMIN_ACCESS_SLUG = '/no-admin-access';

// Sub Routes 
export const EDIT_LOCATION_SLUG = '/edit';
export const ADD_LOCATION_SLUG = '/location/add';

//Alert Keys
export const TOGGLE_MODAL_KEY = 'toggle-modal-key'
export const LOGOUT_ALERT_MODAL_KEY = 'logout-alert-key'
export const ACTIVITY_KEY = 'activity-member-key'
export const DELETE_LOCATION_MODAL_KEY = 'delete-location-alert-key'

export class Routes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            supportedScreen: window.matchMedia("(min-width: 1000px)").matches,
            isActivityModalActive: false,
            isLogoutAlertModalActive: false,
            deleteLocationAlertModal: {
                isDeleteLocationAlertModalActive: false,
                location: null
            },
        }
    }

    componentDidMount() {
        this.init()
    }

    init = () => {
        this.setModalEventListener()
        window.matchMedia("(min-width: 1000px)").addEventListener('change', this.mediaEvent);
    }

    disableModalEventListener = () => {
        GlobalEvent.removeEventListener(this.modalListener)
    }

    setModalEventListener = () => {
        this.modalListener = GlobalEvent.addEventListener(TOGGLE_MODAL_KEY, (res) => {
            switch (res.modalType) {
                case (LOGOUT_ALERT_MODAL_KEY):
                    if (res.active) {
                        this.onEnableLogoutAlertModal()
                    } else {
                        this.onDisableLogoutAlertModal()
                    }
                    break

                case (DELETE_LOCATION_MODAL_KEY):
                    if (res.active) {
                        this.onEnableDeleteLocationAlertModal(res.payload)
                    } else {
                        this.onDisableDeleteLocationAlertModal()
                    }
                    break

                case (ACTIVITY_KEY):
                    if (res.active) {
                        this.onEnableActivityModal()
                    } else {
                        this.onDisableActivityModal()
                    }
                    break
            }
        })
    }

    mediaEvent = (e) => {
        this.setState({ supportedScreen: e.matches })
    }

    onLogoutUser = () => {
        const { userContext } = this.props

        userContext.onLogOut()
    }

    onDisableActivityModal = () => {
        this.setState({ isActivityModalActive: false })
    }

    onEnableActivityModal = () => {
        this.setState({ isActivityModalActive: true })
    }

    onEnableLogoutAlertModal = () => {
        this.setState({ isLogoutAlertModalActive: true })
    }

    onDisableLogoutAlertModal = () => {
        this.setState({ isLogoutAlertModalActive: false })
    }

    onEnableLogoutAlertModal = () => {
        this.setState({ isLogoutAlertModalActive: true })
    }

    onDisableLogoutAlertModal = () => {
        this.setState({ isLogoutAlertModalActive: false })
    }

    onEnableDeleteLocationAlertModal = (location) => {
        this.setState({
            deleteLocationAlertModal: {
                isDeleteLocationAlertModalActive: true,
                location: location
            }
        })
    }

    onDisableDeleteLocationAlertModal = () => {
        this.setState({
            deleteLocationAlertModal: {
                isDeleteLocationAlertModalActive: false,
                location: null
            }
        })
    }

    render() {
        const { } = this.props;
        const { isLogoutAlertModalActive, deleteLocationAlertModal, supportedScreen } = this.state;

        return (
            <div>

                <Switch>

                    <Route exact path="/" render={() => {
                        return <Redirect to={LOCATIONS_SLUG} push />
                    }} />

                    <ProtectedRoute
                        exact
                        path={LOCATIONS_SLUG}
                        render={() => <LocationsPage />}
                    />

                    <ProtectedRoute
                        exact
                        path={LOCATIONS_SLUG + '/:locationId'}
                        render={({ match }) => <LocationDetailPage locationId={match.params.locationId} />}
                    />

                    <ProtectedRoute
                        exact
                        path={LOCATIONS_SLUG + '/:locationId' + EDIT_LOCATION_SLUG}
                        render={({ match }) => <EditLocationPage locationId={match.params.locationId} />}
                    />

                    <ProtectedRoute
                        exact
                        path={LOCATIONS_SLUG + ADD_LOCATION_SLUG}
                        render={() => <EditLocationPage />}
                    />

                    <ProtectedRoute
                        exact
                        path={MANAGE_MEMBERS_SLUG}
                        render={() => <ManageMembersPage />}
                    />

                    <ProtectedRoute
                        exact
                        path={MANAGE_MEMBERS_SLUG + '/:member'}
                        render={({ match }) => <MemberDetailPage member={match.params.member} />}
                    />

                    <ProtectedRoute
                        exact
                        path={MANAGE_MEMBERS_SLUG + '/:member' + "/edit"}
                        render={({ match }) => <EditMemberDetailPage member={match.params.member} />}
                    />

                    <ProtectedRoute
                        exact
                        path={REWARDS_SLUG + '/:reward'}
                        render={({ match }) => <RewardDetailPage reward={match.params.reward} />}
                    />

                    <ProtectedRoute
                        exact
                        path={REWARDS_SLUG + '/:reward' + "/edit"}
                        render={({ match }) => <EditRewardPage member={match.params.member} />}
                    />

                    <ProtectedRoute
                        exact
                        path={REWARDS_SLUG}
                        render={() => <RewardsPage />}
                    />

                    <Route
                        exact
                        path={LOGIN_SLUG}
                        render={() => <LoginPage />}
                    />

                    <Route
                        exact
                        path={FORGOT_PASSWORD_SLUG}
                        render={() => <ForgotPasswordPage />}
                    />

                    <Route
                        exact
                        path={NO_ADMIN_ACCESS_SLUG}
                        render={() => <NotAdministratorAccessPage />}
                    />

                    <Route component={NotFoundPage} />

                </Switch>

                {(isLogoutAlertModalActive) && (
                    <LogoutAlert />
                )}

                {(!supportedScreen) && (
                    <ScreenNotSupported />
                )}

                {(deleteLocationAlertModal.isDeleteLocationAlertModalActive) && (
                    <DeleteLocationAlert location={deleteLocationAlertModal.location} />
                )}
            </div>

        )
    }
}