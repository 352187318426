
import React, { useState } from 'react'
import PropTypes from 'prop-types';

import EyeClosedOpen from '../assets/icons/eye-closed.png'
import { BLUE, GREY1, RED } from '../theme';

export const Input = (props) => {
    const { placeholder, type, background, borderColor, headline, onUpdateText, showErrStyle, value, editable } = props;

    function onChangeText(evt) {
        onUpdateText(evt.target.value)
    }


    return (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
            <div className="F3 navy pb-1" style={{ display: 'flex', flex: 1 ,  marginTop: '10px', flexDirection: 'row',  textTransform: 'uppercase'}}>
                {headline}
                <div style={{ height: '8px', width: '8px', borderRadius: '8px', backgroundColor: (showErrStyle) ? RED : BLUE, marginLeft: '10px' }} />
            </div>

            <div class="input-container" style={{ backgroundColor: editable ? background : GREY1, borderColor: editable ? background : GREY1 }} >

                {editable
                    ? <input
                        placeholder={placeholder}
                        class="input"
                        type={'text'}
                        value={value}
                        onChange={(event) => onChangeText(event)}
                    />
                    : <div className='F2'>{value}</div>
                }

                {(type === 'password') && (
                    <img
                        src={EyeClosedOpen}
                        style={styles.icon2}
                        class="ml-1"
                    />
                )}
            </div>
        </div>

    )
}

const styles = {
    icon: {
        height: '1em',
        width: '1em',
    },

    icon2: {
        height: '1.5em',
        width: '1.5em',
    },
}



Input.prototypes = {
    placeholder: PropTypes.string,
    type: PropTypes.string,
    borderColor: PropTypes.string,
    background: PropTypes.string,
    headline: PropTypes.string,
    value: PropTypes.string,
    editable: PropTypes.bool,
    showErrStyle: PropTypes.bool,
    onUpdateText: PropTypes.func,

}

Input.defaultProps = {
    placeholder: "Placeholder",
    type: "text",
    borderColor: "red",
    background: "red",
    headline: "Headline Text",
    value: "",
    editable: true,
    showErrStyle: false,
    onUpdateText: () => null,
};