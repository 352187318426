import { phone } from 'phone';
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import * as EmailValidator from 'email-validator';

export function filterInt(value) {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value)
  } else {
    return NaN
  }
}

export const onVerifyPhone = (number) => {
  const res = phone(number);
  return res.isValid
}

export const onVerifyString = (string) => {
  if (string !== "" && string.length > 2) {
    return true
  } else {
    return false
  }
}

export const onVerifyEmail = (email) => {
  const res = EmailValidator.validate(email);

  return res
}

export const onVerifyLat = (lat) => {
  const res = validationLatitudeLongitude.latitude(lat);

  return res
}

export const onVerifyLng = (lng) => {
  const res = validationLatitudeLongitude.longitude(lng);

  return res
}

