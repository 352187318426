import React, { useContext } from 'react';
import {
    Route,
    Redirect,
    useLocation
} from 'react-router-dom';

import { UserContext } from '../context'
import { LOGIN_SLUG, NO_ADMIN_ACCESS_SLUG } from '../routes';
import { PortalWrapper } from '../ui-elements'

export const ProtectedRoute = (props) => {
    const location = useLocation();
    const userContext = useContext(UserContext);
    const { isAuthenticated, isAdmin } = userContext;

    return isAuthenticated
        ? isAdmin
            ? 
            <PortalWrapper>
                <Route {...props} />
            </PortalWrapper>
            : <Redirect
                to={{
                    pathname: NO_ADMIN_ACCESS_SLUG,
                    state: { from: location }
                }}
            />
        : <Redirect
            to={{
                pathname: LOGIN_SLUG,
                state: { from: location }
            }}
        />
}