import React from 'react'

export const ScreenNotSupported = (props) => {
    const { } = props;

    return (
        <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: 1000 }}>
            <div className="login distribute distribute-center">
                <div className="distribute distribute-vertical ">
                    <div class="F5 navy" style={{ alignSelf: 'center', marginBottom: '30px' }}>Screen Not Supported</div>
                    <div class="F2 navy" style={{ alignSelf: 'center', marginBottom: '30px' }}>Mobile devices are not supported.</div>
                </div>
            </div>
        </div>

    )
}
