import React from 'react'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import { Loading } from '../../ui-elements';

export const ListDetail = ({ components, data, loading }) => {
    const { headerComponents, renderBodyComponent } = components

    return (
        <div>
            <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', paddingLeft: '5%', paddingRight: '5%' }} className="">
                {headerComponents.map((renderComponent, index) => {
                    return (
                        renderComponent()
                    )
                })}
            </div>

            <div style={{ height: '2.5px', width: '100%' }} className="bg-blue " >

                <div style={{ height: '10px', width: '100%' }} />

                {(loading)
                    ? <div style={{ display: 'flex', width: '100%', height: '50vh', justifyContent: 'center', alignItems: 'center' }}>
                        <Loading />
                    </div>
                    : (data.length === 0)
                        ? <div style={{ display: 'flex', width: '100%', height: '50vh', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                            <div className="F4 navy" style={{ marginBottom: '15px' }}>No Data</div>
                        </div>
                        : data.map((tab, index) => renderBodyComponent(tab))
                }

                <div style={{ height: '20px', width: '100%' }} />

            </div>
        </div >

    )
}