import React from 'react'
import { useHistory } from 'react-router-dom';

import { dashString } from '../../helpers';
import { MANAGE_MEMBERS_SLUG } from '../../routes';
import { NAVY } from '../../theme';
import { Button } from '../../ui-elements';


const TitleHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '10px' }}>
            <div className="F3 navy">NAME</div>
        </div>
    )
}

const StatusHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '10px' }}>
            <div className="F3 navy" style={{ textAlign: 'center' }}>PHONE NUMBER</div>
        </div>
    )
}

const TermHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', marginBottom: '10px' }}>
            <div className="F3 navy" style={{ textAlign: 'center' }}>REWARD POINTS</div>
        </div>
    )
}


const StatsHeaderDiv = () => {
    return (
        <div style={{ flex: 1, alignItems: 'center', display: 'flex', marginBottom: '10px' }}>

            <div style={{ flex: 1 }}>
                <div className="F3 navy" style={{ textAlign: 'center' }}>EMAIL</div>
            </div>

        </div>
    )
}

const ListDetail = (props) => {
    const { nameFirst, nameLast, photoUrl, email, rewardBalance, phone, id } = props
    const history = useHistory()
    const isNameComplete = (nameFirst || nameLast) ? true : false;
    const nameFull = (isNameComplete) ? nameFirst + " " + nameLast : "N/A";

    function onNavMemberDetail() {




        history.push({
            pathname: MANAGE_MEMBERS_SLUG + '/' + dashString(isNameComplete ? nameFull : "member"),
            state: {
                member: {
                    ...props
                }
            }
        })
    }

    return (
        <div className="cvs-detail-container" style={{ paddingRight: '2.5%', paddingLeft: '2.5%' }} onClick={onNavMemberDetail}>

            <div style={{ flex: 2, alignItems: "center", flexDirection: 'row', display: 'flex', justifyContent: "space-evenly" }}>
                <div className="F4 navy mr-2" >{nameFull}</div>
            </div>

            <div style={{ flex: 2, }}>
                <div className="F2 navy" style={{ textAlign: 'center' }}>{(phone) ? phone : "N/A"}</div>
            </div>

            <div style={{ flex: 2, }}>
                <div className="F2 navy" style={{ textAlign: 'center' }}>{rewardBalance}</div>
            </div>

            <div style={{ flex: 2, alignItems: 'center', display: 'flex' }} >
                <div className="F2 navy" >{(email) ? email : "N/A"}</div>
            </div>
        </div>
    )
}


export function ManageMembersList() {
    this.headerComponents = [TitleHeaderDiv, StatusHeaderDiv, TermHeaderDiv, StatsHeaderDiv];
    this.renderBodyComponent = (data) => <ListDetail {...data} />
}

