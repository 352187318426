import { useState, } from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import {
    useHistory,
} from 'react-router-dom';

import { onLogin } from '../api'
import { LoginForm, Button } from '../ui-elements'
import HeaderImage from '../assets/images/header.png';
import WoodiesLogo from '../assets/images/logo.png';
import EmailIcon from '../assets/icons/email.png';
import LockIcon from '../assets/icons/lock.png';
import { BLUE, UNIT_VERTICAL } from '../theme';
import { FORGOT_PASSWORD_SLUG, LOCATIONS_SLUG } from '.';

export const LoginPage = () => {
    const history = useHistory();
    const [activityActive, toggleActivity] = useState(false);
    const [email, toggleEmail] = useState('');
    const [password, updatePassword] = useState('');
    const [error, updateError] = useState({
        active: false,
        message: ""
    });

    async function _onLogin() {
        try {
            toggleActivity(true)

            await onLogin(email, password);

            setTimeout(() => {
                history.push({ pathname: LOCATIONS_SLUG })
                toggleActivity(false)
            }, 600)

        } catch (e) {
            console.log("LOGIN ERR", e.code)

            switch (e.code) {
                case ('auth/invalid-password'):


                    updateError({
                        active: true,
                        message: "Your email or password is not valid"
                    })
                    break

                case ('auth/invalid-email'):

                    updateError({
                        active: true,
                        message: "Your email or password is not valid"
                    })
                    break

                    case ('auth/wrong-password'):

                        updateError({
                            active: true,
                            message: "Your password is not valid"
                        })
                        break
            }

            toggleActivity(false)
        }
    }

    function onNavPassword() {
        history.push({
            pathname: FORGOT_PASSWORD_SLUG
        })

    }

    return (
        <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
        
            <img src={HeaderImage} style={{ width: '2750px', height: '100px', zIndex: 1000 }} />

            <div className="login distribute distribute-center" style={{ marginTop: '-100px' }}>

                <img src={WoodiesLogo} style={{ width: '20vw', height: 'auto', marginBottom: '5vh', marginTop: '0', zIndex: 1000 }} />

                <div className="login-container">
                    <div class="F5 mb-2 navy" style={{ alignSelf: 'flex-start' }}>Admin Login</div>
                    <div class="F2 pb-1 navy" style={{ alignSelf: 'flex-start' }}>Please log in to access the Woodie's backend portal.</div>

                    <LoginForm
                        iconSource={LockIcon}
                        placeholder={"Email"}
                        onUpdateText={(text) => toggleEmail(text)}
                        type={'text'} />

                    <LoginForm
                        width={'100%'}
                        onUpdateText={(text) => updatePassword(text)}
                        iconSource={EmailIcon}
                        placeholder={"Password"}
                        type={'password'} />

                    {(!activityActive)
                        ? <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', marginTop: UNIT_VERTICAL * 10 }}>
                                <div
                                    className="F2 grey3"
                                    style={{ alignSelf: 'center', cursor: 'pointer', width: '100%' }}
                                    onClick={onNavPassword}
                                >
                                    Forgot Password
                                </div>

                                <Button
                                    width={'100%'}
                                    text={"LOGIN"}
                                    backgroundColor={BLUE}
                                    textColor={'white'}
                                    onClick={_onLogin} />
                            </div>

                            {error.active && (
                                <div className="distribute distribute-center ">
                                    <div class="F3 red" style={{ alignSelf: 'center', marginTop: '15px' }}>{error.message}</div>
                                </div>
                            )}

                        </div>

                        : <div style={{ marginTop: '30px' }}>
                            <Dots />
                        </div>

                    }
                </div >
            </div >
        </div >

    )
}