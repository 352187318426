import React, { useState } from 'react'
import { Membership } from '../classes';

import VanIcon from '../assets/icons/van.png'

export const MembershipDetail = (props) => {
    const [] = useState(null);
    const { tierId, name, photoUrl, make, license } = props;
    const membership = Membership.getMembershipTier(tierId)

    return (
        <div className='membership-detail'>
            <div className='distribute distribute-horizontal distribute-even pb-0 pt-0'>
                <div>
                    {(photoUrl === null)
                        ? <div className='car-container distribute distribute-center mr-1' >
                            <img src={VanIcon} className="van-icon" />
                        </div>
                        : <div className='car-container mr-1' style={{ overflow: 'hidden' }}>
                            <img src={photoUrl} style={{  objectFit: 'cover', width: '100%' }} className="mr-2" />
                        </div>
                    }
                </div>

                <div className='pt-1 pb-1'>
                    <div className='F3 navy' style={{ textTransform: 'uppercase', paddingBottom: '.8vh' }}> License Plate</div>
                    <div className='F5 navy'>{license}</div>
                </div>
            </div>

            <img src={membership.featureImage} style={{ height: '60px', width: '60px', objectFit: 'cover' }} className="mr-2" />

        </div>
    )
}
