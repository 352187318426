import React from 'react'
import { useHistory } from 'react-router-dom'
import { dashString } from '../../helpers'
import { REWARDS_SLUG } from '../../routes'

const TitleHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'flex-start', alignItems: 'center', display: 'flex', marginBottom: '10px' }}>
            <div className="F3 navy">ACTION</div>
        </div>
    )
}


const StatsHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', display: 'flex', marginBottom: '10px' }}>

            <div style={{ flex: 1 }}>
                <div className="F3 navy" style={{ textAlign: 'center' }}>POINT VALUE</div>
            </div>

        </div>
    )
}


const ListDetail = (props) => {
    const { name, pointValue } = props
    const history = useHistory()

    function onNavigateRewardDetail() {
        history.push({
            pathname: REWARDS_SLUG + '/' + dashString(name),
            state: {
                reward: props
            }
        })
    }

    return (
        <div className="cvs-detail-container" style={{ paddingRight: '5%', paddingLeft: '5%', cursor: 'pointer' }} onClick={onNavigateRewardDetail}>

            <div style={{ flex: 1 }}>
                <div className="F4 navy" >{name}</div>
            </div>

            <div style={{ flex: 1 }}>
                <div className="F2 navy" style={{ textAlign: 'center' }}>{pointValue}</div>
            </div>

        </div>
    )
}


export function RewardsList() {
    this.headerComponents = [TitleHeaderDiv, StatsHeaderDiv];
    this.renderBodyComponent = (data) => {

        return (
            <ListDetail {...data} />
        )
    }
}

