import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { REWARDS_SLUG } from '.'
import { dashString } from '../helpers'
import { RED } from '../theme'

import { Button, Input } from '../ui-elements'

export class RewardDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    componentDidMount() {

    }



    onNavEditReward = () => {
        const { location, history } = this.props;
        const { name } = location.state.reward

        history.push({
            pathname: REWARDS_SLUG + '/' + dashString(name) + "/edit",
            state: {
                reward: {
                    ...location.state.reward
                }
            }
        })
    }

    render() {
        const { } = this.state;
        const { location } = this.props;
        const { reward } = location.state;
        const { name, pointValue, repeatable } = reward

        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                <div className="location-box-container mr-2">
                    <div className="F5 navy" style={{ marginBottom: '15px' }}>{name}</div>

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter Point Value'}
                        headline={'POINT VALUE'}
                        showErrStyle={false}
                        editable={false}
                        value={pointValue}
                    />

                    <div className="mb-2 distribute distribute-row" >

                        <Button
                            width={'95%'}
                            onClick={this.onNavEditReward}
                            textColor={'white'}
                            backgroundColor={RED}
                            text={"Edit Reward"} />
                    </div>

                </div>

                <div className="location-box-container">

                </div>
            </div>
        )
    }
}

export default withRouter(RewardDetailPage);


