import { Component } from 'react';
import { withRouter } from 'react-router';
import GlobalEvent from 'js-events-listener'

import { Button, Input, DropZone, } from '../ui-elements'
import { BLUE, RED } from '../theme'
import { DELETE_LOCATION_MODAL_KEY, LOCATIONS_SLUG, TOGGLE_MODAL_KEY, EDIT_LOCATION_SLUG } from './'
import { dashString } from '../helpers';
import { DayDetail } from '../components/day-picker';

class LocationDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {

        }
    }

    onEditLocation = () => {
        const { name } = this.props.location.state.location

        this.props.history.push({
            pathname: LOCATIONS_SLUG + '/' + dashString(name) + EDIT_LOCATION_SLUG,
            state: {
                location: { ...this.props.location.state.location }
            }
        })
    }


    onEnableDeleteLocationAlert = () => {
        GlobalEvent.emit(TOGGLE_MODAL_KEY, {
            active: true,
            modalType: DELETE_LOCATION_MODAL_KEY,
            payload: this.props.location.state.location
        })
    }


    render() {
        const { } = this.props
        const { name, address, geolocation, description, phone, publicReviewUrl, hoursOfOperation, backgroundImgUrl } = this.props.location.state.location

        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>
                <div className="location-page-container ml-2 mr-2" >
                    <div className="location-box-container">

                        <div className="F5 navy" style={{ marginBottom: '15px' }}>{name} </div>
                        <div className="F3 navy" style={{ marginBottom: '25px', }}>{description} </div>

                        <div className="F4 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto' }}>
                            LOCATION DETAILS
                        </div>

                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Name'}
                            headline={'NAME'}
                            showErrStyle={false}
                            editable={false}
                            value={name} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Address'}
                            headline={'ADDRESS'}
                            value={address}
                            showErrStyle={false}
                            editable={false}/>
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Location'}
                            value={geolocation._lat}
                            headline={'LATITUDE'}
                            showErrStyle={false}
                            editable={false} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Location'}
                            headline={'LONGITUDE'}
                            showErrStyle={false}
                            editable={false}
                            value={geolocation._long} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'About Location'}
                            headline={'ABOUT'}
                            showErrStyle={false}
                            editable={false}
                            value={description} />
                        <Input
                            background={'white'}
                            borderColor={'white'}
                            placeholder={'Enter Phone Number'}
                            headline={'PHONE NUMBER'}
                            value={phone}
                            showErrStyle={false}
                            editable={false} />

                        <div className="F4 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto' }}>
                            LOCATION HOURS
                        </div>

                        {hoursOfOperation.map((day, index) => {
                            return (
                                <DayDetail {...day} removeDayTime={() => this.onRemoveDayTime(index)} editable={false}/>
                            )
                        })}


                        <div className="mb-2 distribute distribute-vertical" >
                            <Button
                                width={'95%'}
                                onClick={this.onEditLocation}
                                textColor={'white'}
                                backgroundColor={BLUE}
                                text={"Edit Location"} />

                            <Button
                                width={'95%'}
                                onClick={this.onEnableDeleteLocationAlert}
                                textColor={'white'}
                                backgroundColor={RED}
                                text={"Delete Location"} />
                        </div>


                    </div>
                </div>

                <div className="location-box-container" >

                    <div className="F4 navy" style={{ marginBottom: '10px', marginTop: '10px', width: 'auto' }}>
                        LOCATION BACKGROUND IMAGE
                    </div>

                    <div className="drop-zone">
                        <div className={'container'} style={{ height: '150px', width: '450px' }}>
                            <img
                                src={backgroundImgUrl}
                                className="image"
                            />
                        </div>
                    </div>
                </div>



            </div>
        )
    }
}

export default withRouter(LocationDetailPage)
