import { getFirestore, collection, getDocs, setDoc, GeoPoint, doc, deleteDoc } from "firebase/firestore"
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import { asyncForEach, toStandardTime } from "../helpers";
import { weekDayOptions } from "../theme";

export function getLocations() {
    return new Promise(async (resolve, reject) => {
        try {

            let locations = []

            const db = getFirestore()
            const snapshot = await getDocs(collection(db, "locations"));

            snapshot.forEach((doc) => {
                locations.push({ ...doc.data(), id: doc.id })
            });

            await asyncForEach(locations, async (location, index) => {
                location.hoursOfOperation = await getHoursOfOperation(location.id)
            })

            resolve(locations)
        } catch (error) {
            reject(error)
        }
    })
}

function getHoursOfOperation(locationName) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await getFirestore()
            const snapshot = await getDocs(collection(db, "locations", locationName, 'hoursOfOperation'));

            let times = snapshot.docs.map(doc => doc.data());

            times.map((time, index) => {

                if (time.close === null || time.open === null) return

                time.standardOpen = toStandardTime(time.open)
                time.standardClose = toStandardTime(time.close)
                time.open = time.open
                time.close = time.close
            })

            times.sort((a, b) => {
                return a.order - b.order
            });

            resolve(times)
        } catch (error) {
            reject(error)
        }
    })
}


export function addLocation(data) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await getFirestore()
            const id = (data.id) ? data.id : uuidv4()
            let imageUrl = data.backgroundImgUrl;

            if (data.isImageUpdated) {
                const imagePathName = 'locations/' + id + '/backgroundImage.png'
                await onUploadPhoto(imageUrl, imagePathName);
                imageUrl = await onDownloadImage(imagePathName)
            }


            const payload = {
                name: data.name,
                address: data.address.toString(),
                geolocation: new GeoPoint(Number(data.lat), Number(data.lng)),
                description: data.about.toString(),
                phone: data.phoneNumber.toString(),
                backgroundImgUrl: imageUrl,
                locationCode: data.locationCode.toString(),
                publicReviewUrl: data.reviewLink.toString(),
                id: id,
                schemaVersion: '1.0',
            }

            if (data.daysOfWeek.length === 0) return

            const locationRef = doc(db, 'locations', id)
            await setDoc(locationRef, payload)

            weekDayOptions.map(async (weekDay, index) => {

                const res = data.daysOfWeek.find(({ order }) => order === weekDay.order)

                if (res === undefined) {
                    //If no day is found in new location it assumed for that day is the location is closed 
                    // close and open are set to null and need to be checked on mobile client

                    const childRef = doc(locationRef, 'hoursOfOperation/' + weekDay.value)
                    await setDoc(childRef, {
                        name: weekDay.value,
                        close: null,
                        open: null,
                        order: weekDay.order
                    })

                    return
                }

                const childRef = doc(locationRef, 'hoursOfOperation/' + res.name)
                await setDoc(childRef, {
                    name: res.name,
                    close: res.close,
                    open: res.open,
                    order: res.order
                })
            })

            console.log("successfully added new location")
            resolve()
        } catch (error) {
            reject(error)
        }
    })
}

export function deleteLocation(id) {
    const db = getFirestore()
    const locationRef = doc(db, 'locations', id);

    return deleteDoc(locationRef)
}

export const onUploadPhoto = (imageUri, firebaseStoragePath) => {
    return new Promise(async (resolve, reject) => {
        try {
            const storage = await getStorage()
            const locationRef = ref(storage, firebaseStoragePath)

            uploadBytes(locationRef, imageUri).then((snapshot) => {
                resolve()
            });

        } catch (e) {
            reject(e)
        }
    })
}

export function onDownloadImage(firebaseStoragePath) {
    return new Promise(async (resolve, reject) => {
        try {
            const storage = await getStorage()
            const imageRef = await ref(storage, firebaseStoragePath);
            const imageUrl = await getDownloadURL(imageRef)

            resolve(imageUrl)

        } catch (e) {
            reject(e)
        }
    })
}
