import React from 'react'

import MoreIcon from '../assets/icons/more-horizontal.png'
import { DropZone } from '../ui-elements'


function CVS() {
    this.name = "Member.csv"
    this.data = '07/15/2021'
}

export const UploadMembersPage = () => {

    const CsvData = [
        new CVS(),
        new CVS(),
        new CVS(),
        new CVS(),
        new CVS(),
        new CVS(),
        new CVS(),
    ]

    return (
        <div className="upload-members-container">
            <div className="F3 navy">ADD PHOTO/MEDIA</div>
            <DropZone dropHeight={'12.5vh'} />

            <div className="mt-2">
                {CsvData.map((cvs, i) => {
                    return (
                        <CvsDetail {...cvs} />
                    )
                })}
            </div>
        </div>
    )
}

export const CvsDetail = () => {
    return (
        <div className="cvs-detail-container" style={{ paddingRight: '5%', paddingLeft: '5%' }}>
            <div className="F4 navy" style={{ width: '20%' }}>MEMBERS.CSV</div>
            <div className="F2 navy" style={{ width: '20%' }}>Uploaded 2 days ago</div>
            <div style={{ width: '50%', justifyContent: 'flex-end', display: 'flex' }} >
                <img src={MoreIcon} style={{ height: 'auto', width: '2.5vw', }} />
            </div>
        </div>
    )
}