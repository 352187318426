import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: "AIzaSyBX_QJUOdSjLmCDvEQ1Va0q1EOLsTI9WGM",
    authDomain: "woodies-wash-shack-82050.firebaseapp.com",
    projectId: "woodies-wash-shack-82050",
    storageBucket: "woodies-wash-shack-82050.appspot.com",
    messagingSenderId: "708590579030",
    appId: "1:708590579030:web:1e896393c85bfc00f13af7",
    measurementId: "G-V3DLTZ4CVK"
};

export const fireApp = initializeApp(firebaseConfig);
