import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getUsers } from '../api'

import { ListDetail, ManageMembersList } from '../components'
import { onSearchFelid } from '../helpers'
import { LIGHT_BLUE, UNIT } from '../theme'
import { SearchBar } from '../ui-elements'

class ManageMembersPage extends Component {
    constructor() {
        super()

        this.state = {
            userData: [],
            toSearchData: [],
            listLoading: false,
            toSearchValue: ''

        }
    }

    componentDidMount() {
        this._getUsers()
    }

    _getUsers = async () => {
        try {
            this.setState({ listLoading: true })

            const users = await getUsers();

            this.setState({ userData: users, listLoading: false })
        }
        catch (error) {
            alert("Error Loading Data")

            this.setState({ listLoading: false })
        }
    }

    onSearch = (toSearch) => {

        const res = onSearchFelid(toSearch, this.state.userData);

        this.setState({ toSearchValue: toSearch, toSearchData: res })
    }



    render() {
        const { userData, listLoading, toSearchData, toSearchValue } = this.state
        const { } = this.props

        return (
            <div className="location-page-container">
                <SearchBar width={'30%'} onSearch={(text) => this.onSearch(text)} />

                <ListDetail
                    components={new ManageMembersList()}
                    data={
                        (toSearchValue != '')
                            ? toSearchData
                            : userData}
                    loading={listLoading} />

            </div>
        )
    }
}

const styles = {
    dayPickerContainer: {
        backgroundColor: LIGHT_BLUE,
        borderRadius: UNIT
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    }
}
export default withRouter(ManageMembersPage);