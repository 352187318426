export const BROWN = '#B68150';
export const DARK_BROWN = '#9A5D25';
export const BLACK = '#171d20';
export const RED = '#EE2B37';
export const NAVY = ' #003B4A';
export const DARK_BLUE = '#C6E9FF';
export const BLUE = ' #00BBFF';
export const LIGHT_BLUE = '#ABDFFF';
export const YELLOW = '#FDD964';

export const WHITE = '#fff';
export const GREY1 = '#eeeeee';
export const GREY2 = '#e0e0e0';
export const GREY3 = '#9e9e9e';
export const GREY4 = '#9e9e9e';
export const GREY5 = '#757575';
