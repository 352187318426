import React from 'react'
import { useHistory } from 'react-router-dom';

import MoreIcon from '../../assets/icons/more-horizontal.png'
import { dashString } from '../../helpers';
import { ADD_LOCATION_SLUG, LOCATIONS_SLUG } from '../../routes';
import { NAVY } from '../../theme';
import { Button } from '../../ui-elements';


const TitleHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'start', alignItems: 'center', display: 'flex', }}>
            <div className="F3 navy">TITLE</div>
        </div>
    )
}

const StatusHeaderDiv = () => {
    return (
        <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
            <div className="F3 navy"></div>
        </div>
    )
}


const StatsHeaderDiv = () => {
    const history = useHistory()

    function onNavAddLocation() {
        history.replace({
            pathname: LOCATIONS_SLUG + ADD_LOCATION_SLUG
        })
    }


    return (
        <div style={{ flex: 1, alignItems: 'center', flexDirection: 'row', display: 'flex' }}>
            <div style={{ flex: 1, }}>
                <div className="F3 navy" style={{ textAlign: 'center' }}></div>
            </div>

            <Button
                onClick={onNavAddLocation}
                text={"Add Location"}
                textColor={"white"}
                backgroundColor={NAVY} />
        </div>
    )
}


const LocationDetail = (props) => {
    const { name, address, locationCode } = props
    const history = useHistory()


    function onNavLocationDetail() {
        history.push({
            pathname: LOCATIONS_SLUG + '/' + dashString(name),
            state: {
                location: props
            }
        })
    }

    return (
        <div className="cvs-detail-container" style={{ paddingLeft: '5%', paddingRight: '5%', cursor: 'pointer' }} onClick={onNavLocationDetail}>
            <div style={{ flex: 1 }}>
                <div className="F4 navy" style={{ paddingBottom: '5px' }}>{name}</div>
                <div className="F2 navy" >{address}</div>

            </div>

            <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <div className="F2 navy" style={{ textAlign: 'center' }}></div>
            </div>

            <div style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', display: 'flex', }}>
                <div style={{ flex: 1, justifyContent: 'center' }}>
                    <div className="F2 navy" style={{ textAlign: 'center' }}></div>
                </div>

                <div style={{ justifyContent: 'center', display: 'flex', flex: 1, width: '50px' }} >
                    <img src={MoreIcon} style={{ height: 'auto', width: '2.5vw', }}/>
                </div>
            </div>
        </div>
    )
}


export function AddLocationDetail() {

    this.headerComponents = [TitleHeaderDiv, StatusHeaderDiv, StatsHeaderDiv];
    this.renderBodyComponent = (data) => {
        return (
            <LocationDetail {...data} />
        )
    }
}

