import React from 'react'
import PropTypes from 'prop-types';
import { Dots } from "react-activity";

import "react-activity/dist/Dots.css";
import { NAVY } from '../theme';

export const Loading = (props) => {
    const { hasBackground } = props


    return (
        <div className={(!hasBackground) ? "" : "bg-light-blue"} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>

            <div className='distribute distribute-center full-height '>
                <div style={{ backgroundColor: 'white', borderRadius: '15px', height: '75px', width: '75px', justifyContent: 'center', alignItems: 'center', display: 'flex', }}>
                    <Dots color={NAVY} />
                </div>
            </div>

        </div>
    )
}

Loading.prototypes = {
    hasBackground: PropTypes.bool

}

Loading.defaultProps = {
    hasBackground: false
};
