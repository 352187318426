import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import GlobalEvent from 'js-events-listener'

import ArrowWhite from '../assets/icons/arrow-white.png';
import ArrowNavy from '../assets/icons/arrow-navy.png';
import { LOCATIONS_SLUG, LOGOUT_ALERT_MODAL_KEY, MANAGE_MEMBERS_SLUG, REWARDS_SLUG, TOGGLE_MODAL_KEY } from '../routes';

export const SideNavigation = () => {
    const history = useHistory();
    const location = useLocation();
    const splitPathName = location.pathname.split('/')

    function onNavigate(slug) {

        history.push(slug)
    }

    async function _onLogoutAlert() {
        GlobalEvent.emit(TOGGLE_MODAL_KEY, {
            active: true,
            modalType: LOGOUT_ALERT_MODAL_KEY
        })
    }


    return (
        <div className="side-position">

            <div className="wood-container" style={{ zIndex: 1000 }}>

                <h1 className="F5 navy pb-1">Management</h1>
                <h1 className="F3 navy" style={{ marginTop: '-15px', marginBottom: '30px' }}>Select an option below to manage Woodie’s wash.</h1>

                <SideNavButton
                    active={splitPathName.includes('locations')}
                    onClick={() => onNavigate(LOCATIONS_SLUG)}
                    text={"Locations"} />

                <SideNavButton
                    active={splitPathName.includes('manage-members')}
                    onClick={() => onNavigate(MANAGE_MEMBERS_SLUG)}
                    text={"Manage Members"} />

                <SideNavButton
                    active={splitPathName.includes('rewards')}
                    onClick={() => onNavigate(REWARDS_SLUG)}
                    text={"Rewards"} />

                <SideNavButton
                    active={false}
                    onClick={_onLogoutAlert}
                    text={"Log Out"} />

            </div>
        </div>
    )
}



const SideNavButton = ({ onClick, text, active }) => {

    return (
        <div class={active ? "side-nav-button-selected" : "side-nav-button"} onClick={onClick}>
            <p className={active ? "F1 white" : "F1 navy"}>{text}</p>
            <img src={active ? ArrowWhite : ArrowNavy} className="side-button-arrow-icon" />
        </div >
    )
}