// Extras Images
import BugPrepImage from '../assets/images/bug.png'
import ShowerImage from '../assets/images/shower.png'
import LavaBathImage from '../assets/images/bubble-mountain.png'
import RainXImage from '../assets/images/drop-x.png'
import WheelCleanerImage from '../assets/images/tire-sparkle.png'
import WaveImage from '../assets/images/wave.png'
import TireImage from '../assets/images/tire.png'
import HeatWaveImage from '../assets/images/heat-wave.png'

// Tier Images
import DropInImage from '../assets/images/drop-in.png'
import HangTenImage from '../assets/images/hang-ten.png'
import WhipOutImage from '../assets/images/wipeout.png'
import PipelineImage from '../assets/images/pipeline.png'

// Background Images
import DiverImage from '../assets/images/diver.png'
import BeachTruckImage from '../assets/images/beach-truck.png'
import OceanIconImage from '../assets/images/ocean-icon.png'
import SurferImage from '../assets/images/surfer.png'
import OceanImage from '../assets/images/ocean.png'

export class MembershipExtra {
    static BugPrep = new MembershipExtra("Bug Prep", BugPrepImage)
    static PreSoak = new MembershipExtra("Pre Soak", ShowerImage)
    static LavaBath = new MembershipExtra("Lava Bath", LavaBathImage)
    static RainX = new MembershipExtra("Rain-X", RainXImage)
    static WheelCleaner = new MembershipExtra("Wheel Cleaner", WheelCleanerImage)
    static TripleFoam = new MembershipExtra("Triple Foam", WaveImage)
    static TireShine = new MembershipExtra("Tire Shine", TireImage)
    static HeatWave = new MembershipExtra("Heat Wave", HeatWaveImage)
    static ExtremeWax = new MembershipExtra("Extreme Wax", WheelCleanerImage)

    constructor(name, imageSource) {
        this.name = name
        this.imageSrc = imageSource
    }
}

export class Membership {
    static SpecialMembership = new Membership(
        "Special Membership",
        "TIER_SPECIAL",
        "You have a special membership! Please contact Woodie's to manage this membership.",
        DiverImage,
        OceanIconImage,
        null,
        null
    )

    static DropIn = new Membership(
        "The Drop In",
        "oP4ynHplA79Wy5GEHGTL",
        "A superior wash with extra care being given to the wheels, and wax.",
        DiverImage,
        DropInImage,
        [MembershipExtra.BugPrep, MembershipExtra.PreSoak, MembershipExtra.LavaBath],
        [MembershipExtra.WheelCleaner]
    )

    static HangTen = new Membership(
        "The Hang Ten",
        "nWNPRm3yC0jiHbaO56SU",
        "Let the upgrades begin! Perfect for a quick and clean ride with our state-of-the-art shine! WASH Includes tire shine and double wheel cleaner.",
        BeachTruckImage,
        HangTenImage,
        [MembershipExtra.BugPrep, MembershipExtra.PreSoak, MembershipExtra.LavaBath, MembershipExtra.RainX],
        [MembershipExtra.WheelCleaner, MembershipExtra.TripleFoam],
    )

    static Wipeout = new Membership(
        "The Wipeout",
        "T4buIy941ljgj0yJA38v",
        "Wipe out and wipe off! Have your ride ready for anything and PROTECT against the elements with our rain repellent and extreme wax!",
        SurferImage,
        WhipOutImage,
        [MembershipExtra.BugPrep, MembershipExtra.PreSoak, MembershipExtra.LavaBath, MembershipExtra.RainX],
        [MembershipExtra.WheelCleaner, MembershipExtra.TripleFoam, MembershipExtra.TireShine]
    )

    static Pipeline = new Membership(
        "The Pipeline",
        "4JqrgXbzH2EiefhlJcaR",
        "The best of the best! Keep your ride smiling with long-lasting protection against that hot Florida sun by coating your board in our ceramic SEAL!",
        OceanImage,
        PipelineImage,
        [MembershipExtra.BugPrep, MembershipExtra.PreSoak, MembershipExtra.LavaBath, MembershipExtra.RainX],
        [MembershipExtra.WheelCleaner, MembershipExtra.TripleFoam, MembershipExtra.TireShine, MembershipExtra.HeatWave, MembershipExtra.ExtremeWax]
    )

    static getMembershipTier = (tierId) => {
        let tier = null

        switch (tierId) {
            case Membership.SpecialMembership.productIdInternal:
                tier = Membership.SpecialMembership

                break;

            case Membership.DropIn.productIdInternal:
                tier = Membership.DropIn

                break;

            case Membership.HangTen.productIdInternal:
                tier = Membership.HangTen

                break;

            case Membership.Wipeout.productIdInternal:
                tier = Membership.Wipeout

                break;

            case Membership.Pipeline.productIdInternal:
                tier = Membership.Pipeline

                break;
        }

        return tier
    }

    constructor(name, id, description, backgroundImage, featureImage, membershipFeatures, membershipExtras) {
        this.name = name
        this.productIdInternal = id
        this.description = description
        this.featureImage = featureImage
        this.backgroundImage = backgroundImage
        this.membershipExtras = membershipExtras
        this.membershipFeatures = membershipFeatures
    }
}