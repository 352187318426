export async function asyncForEach(array = [], callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index);
  }
}

export function toStandardTime(time) {
  let newTime = ""
  let isPm = false
  let amPmString = "AM"

  const splitTime = time.split(":")
  let hour = Number(splitTime[0])
  const min = splitTime[1]

  if (hour >= 12) {
    isPm = true
    amPmString = "PM"
  }

  if (isPm && hour != 12) {

    hour = hour - 12

  }

  if (!isPm && hour === 0) {
    hour = hour + 12
  }


  newTime = hour + ":" + min + amPmString

  return newTime
}


export function dashString(s) {

  s = s.toLowerCase()
  s = s.split(' ').join('-')

  return s
}


export function onSearchFelid(toSearch, searchArray) {
  let results = [];
  
  function compareObjects(o1, o2) {
    var k = '';
    for (k in o1) if (o1[k] != o2[k]) return false;
    for (k in o2) if (o1[k] != o2[k]) return false;
    return true;
  }
  
  function itemExists(haystack, needle) {
    for (var i = 0; i < haystack.length; i++) if (compareObjects(haystack[i], needle)) return true;
    return false;
  }

  for (var i = 0; i < searchArray.length; i++) {
    for (var key in searchArray[i]) {
      if (typeof searchArray[i][key] === 'string') {
        if (searchArray[i][key].indexOf(toSearch) != -1) {
          if (!itemExists(results, searchArray[i])) results.push(searchArray[i]);
        }
      }

    }
  }

  return results
}