import React from 'react'

export const Button = (props) => {
    const { onClick, textColor, width, backgroundColor, text } = props;

    return (
        <div
            style={{ width: width, backgroundColor: backgroundColor }}
            className="solid-button-container  distribute distribute-center"
            onClick={onClick}>

            <div class="F1" style={{ color: textColor, textAlign: 'center' }}>{text}</div>

        </div>
    )
}
