import React, { useCallback, useState, useEffect } from 'react'
import { Dots } from "react-activity";
import { useDropzone } from 'react-dropzone'
import { NAVY, RED } from '../theme';

export const DropZone = ({
    headlineText,
    returnImage,
    dropHeight,
    acceptedFiles,
    showErrStyle,
    imageUrl,
    loading,
    imageHeight,
    imageWidth,
    imageConfigText
}) => {

    const [file, setFiles] = useState(null);

    const onDrop = useCallback(acceptedFiles => {
        const incomingFile = acceptedFiles[0]
        const image = Object.assign(incomingFile, {
            preview: URL.createObjectURL(incomingFile)
        })

        setFiles(image)
        returnImage(image)
    }, [])

    useEffect(() => () => {
        if (file === null) return

        URL.revokeObjectURL(file.preview)
    }, [file]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: acceptedFiles,
        maxFiles: 1
    })


    return (
        <div className="drop-zone">

            <div className="F3 navy" style={{ marginBottom: '5px', marginTop: '10px', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                <div className="F3 navy">{headlineText}</div>
                <div style={{ height: '8px', width: '8px', borderRadius: '8px', backgroundColor: (showErrStyle) ? RED : 'green', marginLeft: '10px' }} />
            </div>


            <div {...getRootProps()} style={{ height: dropHeight }} className=" container mb-2 border" >

                <input {...getInputProps()} />

                {!loading
                    ? <div>
                        <div className="pr-1 pl-1">
                            <p className="F3 navy" style={{ textAlign: 'center' }}>Drop the files here ...</p>
                            <p className="F3 navy" style={{ textAlign: 'center' }}>or click to select files</p>
                            <p className="F2 navy" style={{ textAlign: 'center', fontSize: '.8em' }}>{imageConfigText}</p>
                        </div>
                    </div>
                    : <Dots color={NAVY} />
                }


            </div>

            {!showErrStyle && !loading && (
                <div className={'container'} style={{ height: imageHeight, width: imageWidth }}>
                    <img
                        src={(file != null) ? file.preview : imageUrl}
                        className="image"
                    />
                </div>
            )}
        </div>

    )
}