import React, { useState } from 'react'
import GlobalEvent from 'js-events-listener'
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";

import { LOGOUT_ALERT_MODAL_KEY, TOGGLE_MODAL_KEY } from '../routes'

import { onLogOut } from '../api'
import { Button } from '.'
import { NAVY, RED } from '../theme'

export const LogoutAlert = () => {
    const [activityActive, toggleActivity] = useState(false)

    function onDisableAlert() {
        GlobalEvent.emit(TOGGLE_MODAL_KEY, {
            active: false,
            modalType: LOGOUT_ALERT_MODAL_KEY
        })
    }

    async function _onLogoutAlert() {
        try {
            toggleActivity(true)

            setTimeout(async () => {
                const res = await onLogOut();
                toggleActivity(false)
                onDisableAlert()
            }, 2400)

        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, zIndex: 1000, backgroundColor: 'rgba(220,220,220, .5)' }} >
            <div className="login distribute distribute-center" style={{ marginTop: '0vh', }}>
                <div className="login-container distribute distribute-center">
                    <div class="F5 navy" style={{ alignSelf: 'center', marginBottom: '15px', marginTop: '30px' }}>Logout?</div>
                    <div class="F2 navy" style={{ alignSelf: 'center', marginBottom: '60px' }}>Are you sure you want to log out?</div>

                    {(!activityActive)
                        ? <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%' }}>
                            <Button
                                width={'100%'}
                                text={"No"}
                                backgroundColor={NAVY}
                                textColor={'white'}
                                onClick={onDisableAlert} />

                            <div style={{ width: '15%' }} />

                            <Button
                                width={'100%'}
                                text={"Yes"}
                                backgroundColor={RED}
                                onClick={_onLogoutAlert}
                                textColor={'white'}
                            />
                        </div>
                        :   <Dots color={NAVY} />
                    }


                </div>
            </div>
        </div>
    )
}