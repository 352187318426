import React, { useState } from 'react'
import Select from 'react-select'


export const SelectInput = ({ headline, selectOptions, onChangeSelection }) => {

    return (
        <div style={{ justifyContent: 'space-between', display: 'flex', width: '100%', flexDirection: 'column',  }}>
            <div className="F3 navy" style={{ marginBottom: '-10px', marginTop: '10px', width: 'auto' }}>
                {headline}
            </div>

            <div className="F3 navy" style={{ marginBottom: '15px', marginTop: '15px', width: '100%' }}>
                <Select options={selectOptions} onChange={onChangeSelection} />
            </div>
        </div>
    )
}
