import React, { Component } from 'react'
import { withRouter } from 'react-router';

import { Button, Input, GhostButton, MembershipDetail } from '../ui-elements'
import { BLUE, NAVY, RED } from '../theme'
import { MANAGE_MEMBERS_SLUG } from '.';
import { dashString, onVerifyEmail, onVerifyPhone, onVerifyString } from '../helpers';
import { connectExistingMembership, getUserById, syncMemberships, updateUser } from '../api'
import { Dots } from 'react-activity';

class EditMemberDetailPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: {
                rewardBalance: '',
                sonnysClientId: '',
                nameFirst: '',
                nameLast: "",
                phone: "",
                email: "",
                photoUrl: "",
                vehicles: [],
            },
            error: {
                flag: false,
                message: ''
            },
            sonnyConnection: {
                card: "",
                email: "",
                license: ""
            },
            isSonnyCardValid: false,
            isSonnyEmailValid: false,
            isSonnyLicenseValid: false,
            isRewardBalanceValid: false,
            isSonnysClientIdValid: false,
            isNameFirstValid: false,
            isNameLastValid: false,
            isPhoneValid: false,
            isEmailValid: false,
            isPhotoUrlValid: false,
            isLoading: false,
            isConnectionLoading: false,
        }
    }

    componentDidMount() {
        this.setMemberData()
    }

    setMemberData = (mem) => {
        if (this.props.location.state === undefined) return
        let user = mem

        if (user === undefined) {
            user = this.props.location.state.member;
        }

        this.setState({
            user: {
                rewardBalance: user.rewardBalance,
                sonnysClientId: user.sonnysClientId,
                nameFirst: user.nameFirst,
                nameLast: user.nameLast,
                phone: user.phone,
                email: user.email,
                photoUrl: user.photoUrl,
                vehicles: user.vehicles
            },
            isRewardBalanceValid: user.rewardBalance ? true : false,
            isSonnysClientIdValid: user.sonnysClientId ? true : false,
            isNameFirstValid: user.nameFirst ? true : false,
            isNameLastValid: user.nameLast ? true : false,
            isPhoneValid: user.phone ? true : false,
            isEmailValid: user.email ? true : false,
            isPhotoUrlValid: true,
        })
    }

    onConnectSonnyMembership = async () => {
        try {
            const { sonnyConnection } = this.state;
            const { member } = this.props.location.state;
            const payload = {
                lastFourCreditCard: sonnyConnection.card,
                email: sonnyConnection.email,
                licensePlate: sonnyConnection.license
            };
            this.setState({ isConnectionLoading: true });
            await connectExistingMembership(member.id, payload);
            const updatedUser = await getUserById(member.id);
            this.setMemberData(updatedUser);
        } catch (e) {
            console.log("Error", e);
            debugger
        } finally {
            this.setState({ isConnectionLoading: false });
        }
    }

    onChangeText = (text, type) => {
        switch (type) {
            case ('firstName'):

                const isNameFirstValid = onVerifyString(text);

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        nameFirst: text,
                    },
                    isNameFirstValid
                }))
                break

            case ('lastName'):

                const isNameLastValid = onVerifyString(text)

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        nameLast: text,
                    },
                    isNameLastValid
                }))
                break

            case ('phone'):

                const isPhoneValid = onVerifyPhone(text)

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        phone: text,
                    },
                    isPhoneValid
                }))
                break

            case ('email'):
                const isEmailValid = onVerifyEmail(text);

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        email: text,
                    },
                    isEmailValid
                }))
                break

            case ('rewardBalance'):

                const isRewardBalanceValid = onVerifyString(text)

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        rewardBalance: text,
                    },
                    isRewardBalanceValid
                }))
                break

            case ('sonnysClientId'):

                const isSonnysClientIdValid = onVerifyString(text)

                this.setState(prevState => ({
                    user: {
                        ...prevState.user,
                        sonnysClientId: text,
                    },
                    isSonnysClientIdValid
                }))
                break

            case ('sonnyLicense'):
                const isSonnyLicenseValid = onVerifyString(text)

                this.setState(prevState => ({
                    sonnyConnection: {
                        ...prevState.sonnyConnection,
                        license: text,
                    },
                    isSonnyLicenseValid
                }))
                break

            case ('sonnyEmail'):
                const isSonnyEmailValid = onVerifyEmail(text)

                this.setState(prevState => ({
                    sonnyConnection: {
                        ...prevState.sonnyConnection,
                        email: text,
                    },
                    isSonnyEmailValid
                }))
                break

            case ('sonnyCard'):
                const isSonnyCardValid = onVerifyString(text)

                this.setState(prevState => ({
                    sonnyConnection: {
                        ...prevState.sonnyConnection,
                        card: text,
                    },
                    isSonnyCardValid
                }))
                break

        }
    }

    onCancel = () => {
        const { member } = this.props.location.state;
        const isNameComplete = (member.nameFirst || member.nameLast) ? true : false;
        const nameFull = (isNameComplete) ? member.nameFirst + " " + member.nameLast : "Member";

        this.props.history.push({
            pathname: MANAGE_MEMBERS_SLUG + '/' + dashString(isNameComplete ? nameFull : "member"),
            state: {
                member: {
                    ...member
                }
            }
        })
    }

    onVerifyUser = () => {
        const {
            isRewardBalanceValid,
            isSonnysClientIdValid,
            isNameFirstValid,
            isNameLastValid,
            isPhoneValid,
            isEmailValid,
            isPhotoUrlValid
        } = this.state;

        if (
            !isRewardBalanceValid
            || !isSonnysClientIdValid
            || !isNameFirstValid
            || !isNameLastValid
            || !isPhoneValid
            || !isEmailValid
            || !isPhotoUrlValid
        ) return false

        return true
    }

    onUpdateUser = async () => {
        try {
            const { id } = this.props.location.state.member;

            this.setState({ isLoading: true })

            const flag = this.onVerifyUser()

            if (!flag) {

                const error = {
                    flag: true,
                    message: "Please fill out required felids."
                }

                this.setState({ error: error, isLoading: false })

                return
            }

            await updateUser(id, this.state.user)

            this.props.history.push({
                pathname: MANAGE_MEMBERS_SLUG,
            })

            this.setState({ isLoading: false })

        } catch (e) {
            const error = {
                flag: true,
                message: "Error updating user."
            }

            this.setState({ error: error, isLoading: false })
        }
    }

    render() {
        const { member } = this.props.location.state;
        const {
            isRewardBalanceValid,
            isSonnysClientIdValid,
            isNameFirstValid,
            isNameLastValid,
            isPhoneValid,
            isEmailValid,
            sonnyConnection,
            isSonnyLicenseValid,
            isSonnyEmailValid,
            isSonnyCardValid,
            user,
            isLoading,
            error,
            isConnectionLoading
        } = this.state;
        const {
            phone,
            email,
            sonnysClientId,
            nameFirst,
            nameLast,
            rewardBalance,
            vehicles
        } = user;
        const { } = this.props;
        const isNameComplete = (member.nameFirst || member.nameLast) ? true : false;
        const nameFull = (isNameComplete) ? member.nameFirst + " " + member.nameLast : "Member";

        return (
            <div style={{ flexDirection: 'row', width: '100%', display: 'flex' }}>

                <div className="location-box-container mr-2">
                    <div style={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                        <div className="F5 navy" style={{ marginBottom: '15px' }}>{nameFull}</div>
                    </div>

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter First Name'}
                        headline={'FIRST NAME'}
                        showErrStyle={!isNameFirstValid}
                        editable={true}
                        onUpdateText={(text) => this.onChangeText(text, "firstName")}
                        value={nameFirst}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter sunny client id'}
                        headline={'LAST NAME'}
                        onUpdateText={(text) => this.onChangeText(text, "lastName")}
                        showErrStyle={!isNameLastValid}
                        editable={true}
                        value={nameLast}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter a email number'}
                        headline={'EMAIL'}
                        onUpdateText={(text) => this.onChangeText(text, "email")}
                        showErrStyle={!isEmailValid}
                        editable={true}
                        value={email}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter a phone number'}
                        headline={'PHONE NUMBER'}
                        onUpdateText={(text) => this.onChangeText(text, "phone")}
                        showErrStyle={!isPhoneValid}
                        editable={true}
                        value={phone}
                    />

                    <Input
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Enter Reward Points'}
                        headline={'REWARD POINTS'}
                        onUpdateText={(text) => this.onChangeText(text, "rewardBalance")}
                        showErrStyle={!isRewardBalanceValid}
                        editable={true}
                        value={rewardBalance}
                    />

                    <Input
                        editable={false}
                        background={'white'}
                        borderColor={'white'}
                        placeholder={'Sunny client id'}
                        headline={'SONNY CLIENT ID'}
                        showErrStyle={!isSonnysClientIdValid}
                        value={sonnysClientId}
                    />

                    {error.flag && <div className="F3 red" style={{ marginLeft: '16px', marginTop: '16px', marginBottom: '16px', textAlign: 'center' }}>** Error: {error.message}</div>}

                    <div className="mb-2 distribute distribute-row" >
                        {(isLoading === false)
                            ? <>
                                <Button
                                    width={'95%'}
                                    onClick={this.onCancel}
                                    textColor={'white'}
                                    backgroundColor={BLUE}
                                    text={"Cancel"} />

                                <div className="ml-1" />

                                <Button
                                    width={'95%'}
                                    onClick={this.onUpdateUser}
                                    textColor={'white'}
                                    backgroundColor={RED}
                                    text={"Submit"} />
                            </>
                            : <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '100%' }}>
                                <Dots color={NAVY} />
                            </div>
                        }
                    </div>
                </div>

                <div className="location-box-container" >
                    <div className="F5 navy" style={{ marginBottom: '15px' }}>Memberships</div>

                    {(vehicles.length === 0)
                        ? <div>
                            <GhostButton
                                onClick={() => null}>
                                <p>No Membership</p>
                            </GhostButton>

                            <div className='connect-account-container'>
                                <Input
                                    background={'white'}
                                    borderColor={'white'}
                                    placeholder={'Last 4 digits of Credit Card*'}
                                    headline={'Last 4 digits of Credit Card'}
                                    onUpdateText={(text) => this.onChangeText(text, "sonnyCard")}
                                    showErrStyle={!isSonnyCardValid}
                                    editable={true}
                                    value={sonnyConnection.card}
                                />

                                <Input
                                    background={'white'}
                                    borderColor={'white'}
                                    placeholder={'Email Address*'}
                                    headline={'Email Address'}
                                    onUpdateText={(text) => this.onChangeText(text, "sonnyEmail")}
                                    showErrStyle={!isSonnyEmailValid}
                                    editable={true}
                                    value={sonnyConnection.email}
                                />

                                <Input
                                    background={'white'}
                                    borderColor={'white'}
                                    placeholder={'License Plate / RFID Number'}
                                    headline={'License Plate / RFID Number*'}
                                    onUpdateText={(text) => this.onChangeText(text, "sonnyLicense")}
                                    showErrStyle={!isSonnyLicenseValid}
                                    editable={true}
                                    value={sonnyConnection.license}
                                />

                                <div className="mb-2 distribute distribute-row" >
                                    {(isConnectionLoading === false)
                                        ? <>
                                            <Button
                                                width={'50%'}
                                                onClick={this.onConnectSonnyMembership}
                                                textColor={'white'}
                                                backgroundColor={BLUE}
                                                text={"Connect Membership"} />
                                        </>
                                        : <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '100%' }}>
                                            <Dots color={NAVY} />
                                        </div>

                                    }
                                </div>
                            </div>
                        </div>

                        :
                        <div>
                            {vehicles.map((v, i) => <MembershipDetail {...v} />)}
                        </div>
                    }

                </div>

            </div >
        )
    }
}

export default withRouter(EditMemberDetailPage);
