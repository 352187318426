import { getFirestore, collection, getDocs, setDoc, GeoPoint, doc, deleteDoc } from "firebase/firestore"

export async function getRewards() {
    return new Promise(async (resolve, reject) => {
        try {
            let rewards = [];

            const db = getFirestore();
            const snapshot = await getDocs(collection(db, "rewardActions"));


            snapshot.forEach((doc) => {
                rewards.push({ ...doc.data(), id: doc.id })
            });

            resolve(rewards)
        } catch (error) {
            reject(error)
        }
    })
}

export function updateReward(rewardId, payload) {
    const db = getFirestore();
    const rewardRef = doc(db, 'rewardActions', rewardId)

    return setDoc(rewardRef, payload)

}