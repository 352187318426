import React from 'react'
import {
    useHistory,
} from 'react-router-dom';

import HeaderImage from '../assets/images/header.png';
import { Button } from '../ui-elements'
import { onLogOut } from '../api'
import { BLUE } from '../theme';
import { LOGIN_SLUG } from '.';

export const NotAdministratorAccessPage = (props) => {
    const history = useHistory();

    async function goToLogin() {
        try {
            const res = await onLogOut();

            history.push(LOGIN_SLUG)
        } catch (error) {
            
        }
    }

    return (
        <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <img src={HeaderImage} style={{ width: '2750px', height: '100px', zIndex: 1000 }} />

            <div className="distribute distribute-center ">

                <div className="login distribute distribute-center" style={{ marginTop: '-100px', }}>
                    <div className="login-container distribute distribute-center">
                        <div class="F5 navy" style={{ alignSelf: 'center', paddingBottom: '30px' }}>Error No Administrator Access</div>
                        <div class="F2 navy" style={{ alignSelf: 'center', marginBottom: '30px' }}>This account does not have administrator access.</div>

                        <Button
                            width={'100%'}
                            text={"GO BACK TO LOGIN"}
                            backgroundColor={BLUE}
                            textColor={'white'}
                            onClick={goToLogin} />

                    </div>
                </div>

            </div>
        </div>
    )
}