import React from 'react'
import {
    useHistory
} from 'react-router-dom';

import { SideNavigation } from '../components'
import WoodiesLogo from '../assets/images/logo.png';
import HeaderImage from '../assets/images/header.png'
import CarBackgroundImage from '../assets/images/car.png'

export const PortalWrapper = (props) => {
    const history = useHistory()
    const { children } = props

    function redirectHone()  {
        history.push('/');
    }


    return (
        <>
            <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden', zIndex: -1 }}>
                <div className="distribute distribute-center">
                    <img src={CarBackgroundImage} className="car-logo" />
                </div>
            </div>

            <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>

                <img src={HeaderImage} style={{ width: '2750px', height: '100px', zIndex: 1000 }} />

                <div className="logo-position">
                    <div className="distribute distribute-center" style={{ marginTop: '25px' }}>
                        <img src={WoodiesLogo} className="logo" onClick={redirectHone} />
                    </div>
                </div>

                <SideNavigation />

                <div className="page-position">
                    <div className="distribute distribute-center">
                        {children}
                    </div>
                </div>

            </div>

        </>
    );
}
