import { useState, } from 'react';
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import {
    useHistory,
} from 'react-router-dom';

import HeaderImage from '../assets/images/header.png';
import { onForgotPassword } from '../api'
import { LoginForm, Button } from '../ui-elements'
import LockIcon from '../assets/icons/lock.png';
import { NAVY, RED } from '../theme';
import { LOGIN_SLUG } from '.';


export const ForgotPasswordPage = () => {
    const history = useHistory()
    const [email, toggleEmail] = useState('');
    const [activityActive, toggleActivity] = useState(false);
    const [error, updateError] = useState({
        active: false,
        message: ""
    });

    async function _onSendRecoveryEmail() {
        try {
            toggleActivity(true)

            const res = await onForgotPassword(email)

            toggleActivity(false)

            updateError({
                active: true,
                message: "Email sent!"
            })


        } catch (e) {

            console.log("LOGIN ERR", e)

            toggleActivity(false)

            switch (e.code) {

                case ('auth/invalid-email'):

                    updateError({
                        active: true,
                        message: "Your email is not valid"
                    })
                    break
                case ('auth/missing-email'):

                    updateError({
                        active: true,
                        message: "Please enter a email"
                    })
                    break
                case ('auth/user-not-found'):

                    updateError({
                        active: true,
                        message: "This email does not exist in our system. Please enter a valid email."
                    })
                    break
            }
        }
    }

    function onNavLoginPage() {
        history.push({
            pathname: LOGIN_SLUG
        })
    }

    return (

        <div className="bg-light-blue" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, overflow: 'hidden' }}>
            <img src={HeaderImage} style={{ width: '2750px', height: '100px', zIndex: 1000 }} />

            <div className="login distribute distribute-center" style={{ marginTop: '-100px'}}>

                <div className="login-container">
                    <div class="F5 mb-2 navy" style={{ alignSelf: 'flex-start' }}>Forgot Password</div>
                    <div class="F2 pb-1 navy" style={{ alignSelf: 'flex-start' }}>Reset your password via email.</div>

                    <LoginForm
                        iconSource={LockIcon}
                        onUpdateText={(text) => toggleEmail(text)}
                        placeholder={"Email Address"}
                        type={'text'} />
                    {(!activityActive)

                        ? <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', width: '100%', marginTop: '15px' }}>

                                <Button
                                    width={'100%'}
                                    text={"LOGIN INSTEAD"}
                                    backgroundColor={NAVY}
                                    textColor={'white'}
                                    onClick={onNavLoginPage} />

                                <div style={{ width: '25%' }} />

                                <Button
                                    width={'100%'}
                                    text={"SEND RECOVERY"}
                                    backgroundColor={RED}
                                    textColor={'white'}
                                    onClick={_onSendRecoveryEmail}
                                />

                            </div>

                            {error.active && (
                                <div className="distribute distribute-center ">
                                    <div class="F3 red" style={{ alignSelf: 'center', marginTop: '15px' }}>{error.message}</div>
                                </div>
                            )}
                        </div>

                        : <div style={{ marginTop: '30px' }}>
                            <Dots  color={NAVY}/>
                        </div>
                    }
                </div>
            </div>
        </div>

    )
}