
export const weekDayOptions = [
    { value: 'monday', label: 'Monday',  order: 0 },
    { value: 'tuesday', label: 'Tuesday', order: 1 },
    { value: 'wednesday', label: 'Wednesday',  order: 2 },
    { value: 'thursday', label: 'Thursday',  order: 3 },
    { value: 'friday', label: 'Friday',  order: 4 },
    { value: 'saturday', label: 'Saturday',  order: 5 },
    { value: 'sunday', label: 'Sunday', order: 6},
]

export const hourOptions = [
    { value: '', label: '0:00' },
    { value: '', label: '0:15' },
    { value: '', label: '0:30' },
    { value: '', label: '0:45' },

    { value: '', label: '1:00' },
    { value: '', label: '1:15' },
    { value: '', label: '1:30' },
    { value: '', label: '1:45' },

    { value: '', label: '2:00' },
    { value: '', label: '2:15' },
    { value: '', label: '2:30' },
    { value: '', label: '2:45' },

    { value: '', label: '3:00' },
    { value: '', label: '3:15' },
    { value: '', label: '3:30' },
    { value: '', label: '3:45' },

    { value: '', label: '4:00' },
    { value: '', label: '4:15' },
    { value: '', label: '4:30' },
    { value: '', label: '4:45' },

    { value: '', label: '5:00' },
    { value: '', label: '5:15' },
    { value: '', label: '5:30' },
    { value: '', label: '5:45' },

    { value: '', label: '6:00' },
    { value: '', label: '6:15' },
    { value: '', label: '6:30' },
    { value: '', label: '6:45' },

    { value: '', label: '7:00' },
    { value: '', label: '7:15' },
    { value: '', label: '7:30' },
    { value: '', label: '7:45' },

    { value: '', label: '8:00' },
    { value: '', label: '8:15' },
    { value: '', label: '8:30' },
    { value: '', label: '8:45' },

    { value: '', label: '9:00' },
    { value: '', label: '9:15' },
    { value: '', label: '9:30' },
    { value: '', label: '9:45' },

    { value: '', label: '10:00' },
    { value: '', label: '10:15' },
    { value: '', label: '10:30' },
    { value: '', label: '10:45' },

    { value: '', label: '11:00' },
    { value: '', label: '11:15' },
    { value: '', label: '11:30' },
    { value: '', label: '11:45' },

    { value: '', label: '12:00' },
    { value: '', label: '12:15' },
    { value: '', label: '12:30' },
    { value: '', label: '12:45' },

    { value: '', label: '13:00' },
    { value: '', label: '13:15' },
    { value: '', label: '13:30' },
    { value: '', label: '13:45' },

    { value: '', label: '14:00' },
    { value: '', label: '14:15' },
    { value: '', label: '14:30' },
    { value: '', label: '14:45' },

    { value: '', label: '15:00' },
    { value: '', label: '15:15' },
    { value: '', label: '15:30' },
    { value: '', label: '15:45' },

    { value: '', label: '16:00' },
    { value: '', label: '16:15' },
    { value: '', label: '16:30' },
    { value: '', label: '16:45' },

    { value: '', label: '17:00' },
    { value: '', label: '17:15' },
    { value: '', label: '17:30' },
    { value: '', label: '17:45' },

    { value: '', label: '18:00' },
    { value: '', label: '18:15' },
    { value: '', label: '18:30' },
    { value: '', label: '18:45' },

    { value: '', label: '19:00' },
    { value: '', label: '19:15' },
    { value: '', label: '19:30' },
    { value: '', label: '19:45' },

    { value: '', label: '20:00' },
    { value: '', label: '20:15' },
    { value: '', label: '20:30' },
    { value: '', label: '20:45' },

    { value: '', label: '21:00' },
    { value: '', label: '21:15' },
    { value: '', label: '21:30' },
    { value: '', label: '21:45' },

    { value: '', label: '22:00' },
    { value: '', label: '22:15' },
    { value: '', label: '22:30' },
    { value: '', label: '22:45' },

    { value: '', label: '23:00' },
    { value: '', label: '23:15' },
    { value: '', label: '23:30' },
    { value: '', label: '23:45' },

]

export const minuteOptions = [
    { value: 'sunday', label: '0' },
    { value: 'sunday', label: '15' },
    { value: 'sunday', label: '30' },
    { value: 'sunday', label: '45' },
]