import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'

import '../src/css/main.css';
import { UserProvider } from './context'
import { Routes } from './routes'

class App extends Component {

  componentDidUpdate() {
    window.scrollTo(0, 0)
    console.log("App Updated")
  }

  render() {
    return (
      <BrowserRouter>
        <UserProvider>
          <Routes />
        </UserProvider>
      </BrowserRouter>
    );
  }
}


export default App;