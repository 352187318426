import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { getRewards } from '../api'

import { ListDetail, RewardsList } from '../components'
import { onSearchFelid } from '../helpers'
import { LIGHT_BLUE, UNIT } from '../theme'
import { SearchBar } from '../ui-elements'


class RewardsPage extends Component {
    constructor() {
        super()

        this.state = {
            rewardData: [],
            toSearchData: [],
            listLoading: false,
            toSearchValue: ''
        }
    }

    componentDidMount() {
        this.init()
    }

    init = async () => {
        try {
            this.setState({ listLoading: true })
            const res = await getRewards()

            this.setState({ rewardData: res, listLoading: false })

        } catch (error) {
            console.log("Locations Error", error)
            alert("Error Loading Data")

            this.setState({ listLoading: false })
        }
    }

    onSearch = (toSearch) => {
        const res = onSearchFelid(toSearch, this.state.rewardData);

        this.setState({ toSearchValue: toSearch, toSearchData: res })
    }

    render() {

        const { rewardData, listLoading, toSearchValue, toSearchData } = this.state
        const { } = this.props

        return (
            <div className="location-page-container">
                <SearchBar width={'30%'} onSearch={(text) => this.onSearch(text)}/>

                <ListDetail
                    loading={listLoading}
                    components={new RewardsList()}
                    data={(toSearchValue != '') ? toSearchData : rewardData}  />

            </div>
        )
    }
}

const styles = {
    dayPickerContainer: {

        backgroundColor: LIGHT_BLUE,
        borderRadius: UNIT
    },
    pickerContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        display: 'flex',
    }
}
export default withRouter(RewardsPage);